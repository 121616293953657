import getToken from "../Token";
import { useState, useEffect, useContext, useRef } from "react";
import getUsername from "../Account_Infomation/Username";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
import Endpoints from "../../api/endpoints";
import zaplynIconT1 from "../../assets/t1.svg";
import zaplynIconT2 from "../../assets/t2.svg";

// import { Tooltip as ReactTooltip } from "react-tooltip";
import FilterIcon from "../../assets/icons/filter.png";

import OrderCards from "./orders/OrderCards";
import { GlobalContext } from "../GlobalContext";
import { PayContext } from "../PayContext";
import { useQuery, useQueryClient } from "react-query";

import FilterComponent from "./orders/FilterComponent";
import SelectInput from "@mui/material/Select/SelectInput";
import LoadingModal from "../popups/LoadingModal";
import Select from "react-select";
import FilterPopup from "./orders/FilterPopup";
import { selectClasses } from "@mui/material";

const UserMessages = (props) => {
  const buttonRef = useRef(null);
  const filterWrapperRef = useRef(null);
  const queryClient = useQueryClient();
  var link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  const [isAscending, setIsAscending] = useState(true);
  const [isScheduleAscending, setIsScheduleAscending] = useState(true);
  const [col, setCol] = useState("Select order status");
  const {
    showOrdersFilteredData,
    selectedSortType,
    setSelectedSortType,
    setShowOrdersFilteredData,
  } = useContext(GlobalContext);
  const { new_order_socket } = useContext(PayContext);
  //var link = "http://localhost:8000/api/";

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  var curUser = getUsername();
  //combination of filtered global messages and saved messages

  const [page, setPage] = useState(1);
  const [ordersdata, setOrdersdata] = useState("");
  // fetch orders
  const {
    isLoading: isOrdersLoading,
    data: ordersData,
    isError: isOrdersError,
    error: ordersErr,
    isFetching: isOrdersDataFetching,
    isPreviousData: isPrevOrdersData,
    refetch: refetchNewOrdersData,
  } = useQuery(["ordersData", { page }], () => Endpoints.FetchOrderData(page), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  new_order_socket.onmessage = (event) => {
    if (JSON.parse(event.data)["payment_status"] !== "in-progress") {
      setOrdersdata(JSON.parse(event.data)["id"]);
    }
  };

  useEffect(() => {
    if (ordersdata !== "") {
      refetchNewOrdersData();
    }
  }, [ordersdata]);

  const [sortType, setSortType] = useState(selectedSortType);

  const [selectFilterList, setSelectFilterList] = useState([]);
  // const [selectFilterListMobile, setSelectFilterListMobile] = useState([]);

  // const handleFilterSelect = (e) => {
  //   if (e.target.value === "All") {
  //     setSelectFilterList([]);
  //     setShowFilteredData(false);
  //   } else {
  //     const newSelected = [...selectFilterList];

  //     if (newSelected.includes(e.target.value)) {
  //       newSelected.splice(newSelected.indexOf(e.target.value), 1);
  //     } else {
  //       newSelected.push(e.target.value);
  //     }
  //     setSelectFilterList(newSelected);
  //   }
  // };

  const lastPage = () => setPage(ordersData?.pages);

  const firstPage = () => setPage(1);

  const pagesArray = Array(ordersData?.pages)
    .fill()
    .map((_, index) => index + 1);

  const [sortData, setSortData] = useState(null);

  function handleSort() {
    setSelectedSortType(sortType);
    setShowOrdersFilteredData(true);
  }

  useEffect(() => {
    if (ordersData) {
      const moddata = Object.keys(ordersData).map((key) => ({
        key,
        ...ordersData[key],
      }));
      setSortData(moddata);
    }
  }, [ordersData]);

  // console.log(Object.keys(sortData[1].Products)[0].toString());
  // console.log(
  //   Object.values(sortData[1].Products["ged514fmu"].variant).toString()
  // );

  useEffect(() => {
    if (sortType !== "latest") {
      handleSort();
    }
  }, [sortType]);

  // handle settings popup
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isFilterOpen &&
        buttonRef.current &&
        filterWrapperRef.current &&
        !buttonRef.current.contains(event.target) &&
        !filterWrapperRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    }

    function handleScroll() {
      if (isFilterOpen) {
        setIsFilterOpen(false);
      }
    }

    // document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      // document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFilterOpen]);

  if (isOrdersLoading) {
    return <LoadingModal></LoadingModal>;
  }

  if (isOrdersError) {
    return (
      <div className="pt-24 flex flex-col items-center justify-centeri">
        <div className="flex items-center h-full sm:p-16 text-gray-700">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-40 h-40 text-gray-600"
            >
              <path
                fill="currentColor"
                d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              ></path>
              <rect
                width="176"
                height="32"
                x="168"
                y="320"
                fill="currentColor"
              ></rect>
              <polygon
                fill="currentColor"
                points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
              ></polygon>
              <polygon
                fill="currentColor"
                points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
              ></polygon>
            </svg>
            <p className="text-3xl">
              Looks like our services are currently offline
            </p>
            <div className="flex items-center ">
              <img
                src={zaplynIconT1}
                alt=""
                className="h-4 w-4 transform -translate-y-0.5 translate-x-3 "
              />

              <img src={zaplynIconT1} alt="" className="h-4 w-4 " />

              <p className="text-lg bg-gradient-to-b from-purple-500 to-pink-500 text-transparent bg-clip-text text-center ml-2">
                We'll be back soon!
              </p>
              <img
                src={zaplynIconT2}
                alt=""
                className="h-4 w-4  transform -translate-y-0.5 translate-x-3"
              />

              <img src={zaplynIconT2} alt="" className="h-4 w-4 " />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (sortData?.length < 1) {
    return (
      <div className="pt-24 flex flex-col items-center max-w-3xl mx-auto  ">
        <p>No Orders yet!</p>
      </div>
    );
  }
  return (
    <div className="">
      <ToastContainer autoClose={1500} />
      {
        <div className="pt-16 flex flex-col z-20 p-2 max-w-3xl mx-auto pb-20 ">
          <div className="flex flex-col justify-end z-20 items-end  transform translate-y-6 ml-52 pt-0.5  mr-5">
            {/* <p className="text-gray-700 font-semibold text-xs m-1">Filter by</p> */}

            <div className=" relative mr-5">
              <img
                ref={buttonRef}
                src={FilterIcon}
                className={`${
                  isFilterOpen || showOrdersFilteredData ? "bg-pink-300" : ""
                } h-8 w-8 rounded-full p-1 border border-pink-300 shadow-md  cursor-pointer`}
                alt=""
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
              {isFilterOpen && (
                <div ref={filterWrapperRef}>
                  <FilterPopup />
                </div>
              )}
            </div>
          </div>

          <div className="w-full px-5 pb-5 ">
            {!showOrdersFilteredData &&
              sortData !== null &&
              sortData?.length > 0 && (
                <>
                  {sortData?.length > 0 && (
                    <div className=" flex items-end ">
                      <div className="flex flex-col justify-end  items-end  mr-5">
                        {/* <p className="text-gray-700 font-semibold text-xs m-1">
                                Sort by
                              </p> */}
                        <select
                          className="cursor-pointer w-32 md:w-full focus:outline-none py-1 px-2 bg-gradient-to-b from-purple-200 to-pink-300 rounded-full text-xs text-gray-600 shadow-md"
                          required
                          value="select "
                          onChange={(e) => {
                            setSortType(e.target.value);
                          }}
                        >
                          <option value="select">Sort by</option>
                          {[
                            { sm: "Latest First", val: "latest" },
                            { sm: "Oldest Fisrt", val: "oldest" },
                            // {
                            //   sm: "Schedule Date (Latest)",
                            //   val: "schedulesortnew",
                            // },
                            // {
                            //   sm: "Schedule Date (Oldest)",
                            //   val: "schedulesortold",
                            // },
                          ].map((item, index) => (
                            <option
                              key={index}
                              className={` ${
                                selectedSortType === item.val
                                  ? "bg-pink-300"
                                  : ""
                              }`}
                              value={item?.val}
                            >
                              {item?.sm}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className=" py-3 px-2 w-full">
                    <div className="flex justify-center space-x-1 text-gray-900">
                      <button
                        onClick={firstPage}
                        disabled={isPrevOrdersData || page === 1}
                        title="previous"
                        type="button"
                        className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-4"
                        >
                          <path d="M13.9142 12.0001L18.7071 7.20718L17.2929 5.79297L11.0858 12.0001L17.2929 18.2072L18.7071 16.793L13.9142 12.0001ZM7 18.0001V6.00008H9V18.0001H7Z"></path>
                        </svg>
                      </button>
                      {pagesArray.map((pg) => (
                        <button
                          key={pg}
                          type="button"
                          disabled={isPrevOrdersData}
                          className={` ${
                            pg === page ? "bg-pink-300" : ""
                          } inline-flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-pink-300`}
                          onClick={() => setPage(pg)}
                        >
                          {pg}
                        </button>
                      ))}
                      <button
                        onClick={lastPage}
                        disabled={
                          isPrevOrdersData || page === ordersData?.pages
                        }
                        title="next"
                        type="button"
                        className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-4"
                        >
                          <path d="M10.0859 12.0001L5.29297 16.793L6.70718 18.2072L12.9143 12.0001L6.70718 5.79297L5.29297 7.20718L10.0859 12.0001ZM17.0001 6.00008L17.0001 18.0001H15.0001L15.0001 6.00008L17.0001 6.00008Z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {isOrdersDataFetching && (
                    <span className="loading">Loading...</span>
                  )}
                  {sortData
                    .filter((item) => item.key !== "pages")
                    .map((order) => (
                      <OrderCards key={order.order_id} productDetails={order} />
                    ))}
                </>
              )}
            {showOrdersFilteredData && (
              <FilterComponent
                savedmessage={ordersData}
                selectFilterList={selectFilterList}
              />
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default UserMessages;
