import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../GlobalContext.js";
import Cards from "./home/Cards";
import FilterCards from "./home/FilterCards";
import FilterIcon from "../../assets/icons/filter.png";
import Endpoints from "../../api/endpoints";
import { useQuery, useQueryClient } from "react-query";
import PostMessagePopup from "../PostMessagePopup";
import Modal from "react-modal";
import zaplynIconT1 from "../../assets/t1.svg";
import zaplynIconT2 from "../../assets/t2.svg";
import HomeFilterComponent from "./home/HomeFilterComponent.js";
import { useNavigate } from "react-router";

const MessageBoard = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, sestIsLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const {
    fetchedProducts,
    productImages: globalImages,
    filteredData,
    setFilteredData,
    showFilteredData,
    setShowFilteredData,
    selectedHomeFilterOptions,
    selectedButtonFilters,
    setSelectedButtonFilters,
    userData,
  } = useContext(GlobalContext);

  const {
    isLoading: isFilterCategoryLoading,
    data: FilterCategoryList,
    isError: isFilterCategoryError,
  } = useQuery("categoryFilterButtonsData", Endpoints.FetchTopCategory);

  const [page, setPage] = useState(1);
  // fetch products data
  const {
    isLoading: isProductsLoading,
    data: fetchedProductsData,
    isError: isProductsError,
    error: productsErr,
    isFetching: isProductsDataFetching,
    isPreviousData: isPrevProductsData,
  } = useQuery(["productsData", { page }], () => Endpoints.LoadUserData(page), {
    keepPreviousData: true,
  });

  const lastPage = () => setPage(fetchedProductsData?.pages);
  const firstPage = () => setPage(1);
  const [pagesArray, setPagesArray] = useState([]);

  useEffect(() => {
    if (fetchedProductsData && !fetchedProductsData?.status) {
      setPagesArray(
        Array(
          fetchedProductsData?.find((item) => item.hasOwnProperty("pages"))
            .pages
        )
          .fill()
          .map((_, index) => index + 1)
      );
    }
  }, [fetchedProductsData]);

  //create product modal
  const [createProductModalIsOpen, setCreateProductModalIsOpen] =
    useState(false);
  const setCreateProductModalIsOpenToFalse = () => {
    setCreateProductModalIsOpen(false);
  };

  //Function for filterdata using buttons

  function handleButtonFilter(filter) {
    const newSelected = [...selectedButtonFilters];

    if (newSelected.includes(filter)) {
      newSelected.splice(newSelected.indexOf(filter), 1);
    } else {
      newSelected.push(filter);
    }
    setSelectedButtonFilters(newSelected);
  }

  useEffect(() => {
    // console.log("running");
    if (selectedButtonFilters.length > 0) {
      setShowFilteredData(true);
    } else if (
      selectedButtonFilters?.length === 0 &&
      selectedHomeFilterOptions?.length === 0
    ) {
      setShowFilteredData(false);
    }
  }, [selectedButtonFilters]);

  if (isProductsLoading || fetchedProductsData?.status) {
    return <p className="mt-28">Loading...</p>;
  }

  if (isProductsError) {
    return (
      <div className="pt-24 flex flex-col items-center justify-centeri">
        <div className="flex items-center h-full sm:px-16 text-gray-700">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-40 h-40 text-gray-600"
            >
              <path
                fill="currentColor"
                d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              ></path>
              <rect
                width="176"
                height="32"
                x="168"
                y="320"
                fill="currentColor"
              ></rect>
              <polygon
                fill="currentColor"
                points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
              ></polygon>
              <polygon
                fill="currentColor"
                points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
              ></polygon>
            </svg>
            <p className="text-3xl">
              Looks like our services are currently offline
            </p>
            <div className="flex items-center ">
              <img
                src={zaplynIconT1}
                alt=""
                className="h-4 w-4 transform -translate-y-0.5 translate-x-3 "
              />

              <img src={zaplynIconT1} alt="" className="h-4 w-4 " />

              <p className="text-lg bg-gradient-to-b from-purple-500 to-pink-500 text-transparent bg-clip-text text-center ml-2">
                We'll be back soon!
              </p>
              <img
                src={zaplynIconT2}
                alt=""
                className="h-4 w-4  transform -translate-y-0.5 translate-x-3"
              />

              <img src={zaplynIconT2} alt="" className="h-4 w-4 " />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <section className="z-0 pt-24 pb-20 ">
      <ToastContainer autoClose={1500} />
      {/* Filter div */}
      <div className="flex justify-between max-w-3xl mx-auto">
        <div className="flex gap-3 ml-5">
          {FilterCategoryList?.length > 0 &&
            FilterCategoryList[0] !== "" &&
            FilterCategoryList.map((item, index) => (
              <div key={index}>
                <button
                  onClick={() => handleButtonFilter(item)}
                  className={` py-1 px-4  rounded-full text-xs font-semibold  shadow-md ${
                    selectedButtonFilters.includes(item)
                      ? "bg-gradient-to-b from-purple-300 to-pink-500 to-20% text-white"
                      : "bg-gradient-to-b from-purple-200 to-pink-300 to-20% text-gray-600"
                  } `}
                >
                  {item}
                </button>
              </div>
            ))}
        </div>
        {fetchedProductsData?.length > 0 && (
          <div className=" relative mr-5">
            <img
              src={FilterIcon}
              className={`${
                showFilteredData || isFilterOpen ? "bg-pink-300" : ""
              } h-8 w-8 z-50 rounded-full p-1 border border-pink-300 shadow-md cursor-pointer
`}
              alt=""
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            />
            {isFilterOpen && <FilterCards setIsFilterOpen={setIsFilterOpen} />}
          </div>
        )}
      </div>
      {/* main-section */}

      <div className="flex mt-2 lg:max-w-4xl mx-auto ">
        <>
          {showFilteredData ? (
            <div className=" flex flex-col gap-1 mx-auto">
              <HomeFilterComponent />
            </div>
          ) : fetchedProductsData?.length > 1 ? (
            <div className="flex flex-col gap-1 mx-auto">
              <div className=" py-3 px-2 w-full">
                <div className="flex justify-center space-x-1 text-gray-900">
                  <button
                    onClick={firstPage}
                    disabled={isPrevProductsData || page === 1}
                    title="previous"
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4"
                    >
                      <path d="M13.9142 12.0001L18.7071 7.20718L17.2929 5.79297L11.0858 12.0001L17.2929 18.2072L18.7071 16.793L13.9142 12.0001ZM7 18.0001V6.00008H9V18.0001H7Z"></path>
                    </svg>
                  </button>
                  {pagesArray.map((pg) => (
                    <button
                      key={pg}
                      type="button"
                      disabled={isPrevProductsData}
                      className={` ${
                        pg === page ? "bg-pink-300" : ""
                      } inline-flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-pink-300`}
                      onClick={() => setPage(pg)}
                    >
                      {pg}
                    </button>
                  ))}
                  <button
                    onClick={lastPage}
                    disabled={
                      isPrevProductsData ||
                      page === fetchedProductsData[0]?.pages
                    }
                    title="next"
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4"
                    >
                      <path d="M10.0859 12.0001L5.29297 16.793L6.70718 18.2072L12.9143 12.0001L6.70718 5.79297L5.29297 7.20718L10.0859 12.0001ZM17.0001 6.00008L17.0001 18.0001H15.0001L15.0001 6.00008L17.0001 6.00008Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              {isProductsDataFetching && <p>Loading...</p>}
              <div className=" grid  md:grid-cols-2 mx-auto gap-5 ">
                {fetchedProductsData
                  ?.filter((item) => !item.hasOwnProperty("pages"))
                  .map((message) => (
                    <Cards key={message.id} message={message} />
                  ))}
              </div>
            </div>
          ) : (
            <div className=" text-xs md:text-sm col-span-2 ">
              New to Zaplyn Add your product now!{" "}
              <button
                onClick={() => navigate("/add-product")}
                className="py-1 px-4 bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text rounded-lg  font-bold text-sm md:text-lg animate-pulse "
              >
                Click Here !
              </button>
            </div>
          )}
        </>
      </div>
      {/* Create Product Modal */}
      <Modal
        isOpen={createProductModalIsOpen}
        className=" max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setCreateProductModalIsOpen(false)}
      >
        <button
          onClick={setCreateProductModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 button shadow-md focus:outline-none cursor-pointer rounded-md hover:bg-black hover:text-white bg-white text-md font-semibold flex-shrink"
        >
          x
        </button>
        <PostMessagePopup onClick={setCreateProductModalIsOpenToFalse} />
      </Modal>
    </section>
  );
};

export default MessageBoard;
