import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../GlobalContext.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";

const FilterPopup = () => {
  const {
    selectedOrderId,
    selectedProductName,
    selectedCustomerName,
    selectedShopId,
    selectedOrderDate,
    selectedFilterOptions,
    setSelectedFilterOptions,
    setShowOrdersFilteredData,
    setSelectedOrderId,
    setSelectedProductName,
    setSelectedCustomerName,
    setSelectedShopId,
    setSelectedOrderDate,
    setSelectedSortType,
    deliveredDate,
    setDeliveredDate,
    orderStartDate,
    setOrderStartDate,
    orderEndDate,
    setOrderEndDate,
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([
    { value: "Received", label: "Received" },
    { value: "Ready", label: "Ready" },
    { value: "Dispatched", label: "Dispatched" },
    { value: "Out For Delivery", label: "Out For Delivery" },
    { value: "Delivered", label: "Delivered" },
    { value: "Refunded", label: "Refunded" },
  ]);

  const [selectedOrderIdLocal, setSelectedOrderIdLocal] =
    useState(selectedOrderId);
  const [selectedProductNameLocal, setSelectedProductNameLocal] =
    useState(selectedProductName);
  const [selectedCustomerNameLocal, setSelectedCustomerNameLocal] =
    useState(selectedCustomerName);
  const [selectedShopIdLocal, setSelectedShopIdLocal] =
    useState(selectedShopId);
  const [selectedOrderDateLocal, setSelectedOrderDateLocal] =
    useState(selectedOrderDate);
  const [selectedFilterOptionsLocal, setSelectedFilterOptionsLocal] = useState(
    selectedFilterOptions
  );

  const [deliveredDatelocal, setDeliveredDatelocal] = React.useState(
    deliveredDate ?? dayjs()
  );

  const [startDateLocal, setStartDateLocal] = React.useState(
    orderStartDate ?? dayjs()
  );

  const [endDateLocal, setEndDateLocal] = React.useState(
    orderEndDate ?? dayjs()
  );
  const handleOptionChange = (selectedOptions) => {
    setSelectedFilterOptionsLocal(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
    // if (selectedOptions.length === 0) {
    //   setShowOrdersFilteredData(false);
    // }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedOrderId(selectedOrderIdLocal);
    setSelectedProductName(selectedProductNameLocal);
    setSelectedCustomerName(selectedCustomerNameLocal);
    setSelectedShopId(selectedShopIdLocal);
    setSelectedOrderDate(selectedOrderDateLocal);
    setSelectedFilterOptions(selectedFilterOptionsLocal);
    setDeliveredDate(deliveredDatelocal);
    setOrderStartDate(startDateLocal);
    setOrderEndDate(endDateLocal);
    setSelectedSortType("latest");

    setShowOrdersFilteredData(true);
  };

  const handleReset = () => {
    setShowOrdersFilteredData(false);
    setSelectedOrderId("");
    setSelectedProductName("");
    setSelectedCustomerName("");
    setSelectedShopId("");
    setSelectedOrderDate("");
    setSelectedFilterOptions([]);
    setSelectedOrderIdLocal("");
    setSelectedProductNameLocal("");
    setSelectedCustomerNameLocal("");
    setSelectedShopIdLocal("");
    setSelectedOrderDateLocal("");
    setSelectedFilterOptionsLocal([]);
    setDeliveredDate("");
    setDeliveredDatelocal("");
    setOrderStartDate("");
    setStartDateLocal("");
    setOrderEndDate("");
    setEndDateLocal("");
    setSelectedSortType("latest");
  };

  return (
    <div className="">
      <div className="absolute right-0 top-8 z-10 bg-white border border-pink-300 flex flex-col justify-between px-4 leading-tight  rounded-md p-2 text-xs ">
        <div className=" flex flex-col gap-2 ">
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Order Status</p>
            <Select
              className="w-52"
              value={selectedFilterOptionsLocal}
              onChange={handleOptionChange}
              options={options}
              placeholder={"select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              getOptionValue={(option) => option.value} //value is what it is
              getOptionLabel={(option) => option.label} //label is what you see
            />
          </div>
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Order Id</p>
            <input
              onChange={(e) => setSelectedOrderIdLocal(e.target.value)}
              type="number"
              value={selectedOrderIdLocal}
              placeholder="Enter OrderId"
              className="text-xs rounded-lg border border-pink-200 p-2"
            />
          </div>
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Shop Id</p>
            <input
              onChange={(e) => setSelectedShopIdLocal(e.target.value)}
              type="text"
              value={selectedShopIdLocal}
              placeholder="Enter ShopId"
              className="text-xs rounded-lg border border-pink-200 p-2"
            />
          </div>
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Product Name</p>
            <input
              onChange={(e) => setSelectedProductNameLocal(e.target.value)}
              type="text"
              value={selectedProductNameLocal}
              placeholder="Enter Product Name"
              className="text-xs rounded-lg border border-pink-200 p-2"
            />
          </div>
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Customer Name</p>
            <input
              onChange={(e) => setSelectedCustomerNameLocal(e.target.value)}
              type="text"
              value={selectedCustomerNameLocal}
              placeholder="Enter Customer Name"
              className="text-xs  rounded-lg border border-pink-200 p-2"
            />
          </div>
          <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Delivered Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="text-xs  rounded-lg border border-pink-200 "
                onChange={setDeliveredDatelocal}
                value={deliveredDatelocal}
                slotProps={{
                  textField: { size: "small", padding: "0px !important" },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <hr className="" />
          <p>Order Date Range</p>
          <div className="flex items-center gap-2  ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="text-xs  rounded-lg border border-pink-200 "
                onChange={setStartDateLocal}
                value={startDateLocal}
                slotProps={{
                  textField: {
                    size: "small",
                    style: {
                      fontSize: "5px",
                      padding: "1px",
                    },
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="text-xs  rounded-lg border border-pink-200 "
                onChange={setEndDateLocal}
                value={endDateLocal}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {/* <div className="flex items-center gap-2 md:w-60 ">
            <p className=" text-xs mb-1 w-2/5">Order Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className={`bg-white border border-pink-100  `}
                onChange={setSelectedOrderDateLocal}
                value={selectedOrderDate}
                PopperProps={{
                  sx: {
                    "&.MuiPickersPopper-root": { border: "1px solid red" },
                  },
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ opacity: 0, width: 0, height: 0 }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div> */}
        </div>

        <div className="flex items-center justify-around mt-2">
          <button
            className="py-1 px-4 bg-gradient-to-b from-purple-200 to-pink-300 transform active:from-pink-300 active:to-purple-300 active:scale-95 focus:outline-none to-20% rounded-full text-sm text-gray-600 shadow-md w-20 "
            onClick={handleReset}
          >
            Reset
          </button>
          <button
            onClick={handleSubmit}
            className="py-1 px-4 rounded-full bg-gradient-to-b from-purple-200 to-pink-300 transform active:from-pink-300 active:to-purple-300 active:scale-95 focus:outline-none to-20% text-sm text-gray-600 shadow-md w-20"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;
