import React, { useContext, useEffect, useState } from "react";
import getUsername from "../../Account_Infomation/Username";
import OrderCards from "./OrderCards";
import { GlobalContext } from "../../GlobalContext";
import { PayContext } from "../../PayContext";
import { ToastContainer } from "react-toastify";
import getToken from "../../Token";
import { useQuery, useQueryClient } from "react-query";
import LoadingModal from "../../popups/LoadingModal";
import dayjs from "dayjs";
import FilterPopup from "./FilterPopup";

function FilterComponent() {
  const queryClient = useQueryClient();
  const accesstoken = getToken();
  const curUser = getUsername();

  const {
    setShowOrdersFilteredData,
    selectedFilterOptions,
    setSelectedFilterOptions,
    selectedOrderId,
    selectedProductName,
    selectedCustomerName,
    selectedShopId,
    selectedOrderDate,
    selectedSortType,
    deliveredDate,
    orderStartDate,
    orderEndDate,
    setSelectedSortType,
  } = useContext(GlobalContext);
  const { new_order_socket } = useContext(PayContext);
  const [isAscending, setIsAscending] = useState(true);
  const [selectSortType, setSelectSortType] = useState(selectedSortType);

  const [modifiedData, setModifiedData] = useState([]);

  const [page, setPage] = useState(1);

  const FetchFilteredData = async (pageNo) => {
    try {
      const response = await fetch(
        `https://omnistack.org${process.env.REACT_APP_LINK}` +
          "fetch-filter-orders/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: curUser,
            sorting: selectedSortType,
            page: pageNo,
            order_status: selectedFilterOptions
              .map((item) => item.value)
              .toString(),
            order_id: selectedOrderId || "",
            order_dates: selectedOrderDate || "",
            shop_id: selectedShopId || "",
            product_name: selectedProductName || "",
            customer_name: selectedCustomerName || "",
            delivered_date: deliveredDate
              ? dayjs(deliveredDate).format("YYYY-MM-DD")
              : "",
            start_date: orderStartDate
              ? dayjs(orderStartDate).format("YYYY-MM-DD")
              : "",
            end_date: orderEndDate
              ? dayjs(orderEndDate).format("YYYY-MM-DD")
              : "",
          }),
        }
      );
      const json = await response.json();

      return json;
    } catch (error) {
      console.log("");
    }
  };
  const [ordersdata, setOrdersdata] = useState("");
  // fetch filtered orders
  const {
    isLoading: isFliteredOrdersLoading,
    data: fliteredOrdersData,
    isError: isFilteredOrdersError,
    error: filteredOrdersErr,
    isFetching: isFilteredOrdersDataFetching,
    isPreviousData: isFilteredPrevOrdersData,
    refetch: refetchFilteredOrdersData,
  } = useQuery(
    ["filteredOrdersData", { page }],
    () => FetchFilteredData(page),
    {
      keepPreviousData: true,
    }
  );

  new_order_socket.onmessage = (event) => {
    if (JSON.parse(event.data)["payment_status"] !== "in-progress") {
      setOrdersdata(JSON.parse(event.data)["id"]);
    }
  };

  useEffect(() => {
    if (ordersdata !== "") {
      refetchFilteredOrdersData();
    }
  }, [ordersdata]);

  const lastPage = () => setPage(fliteredOrdersData?.pages);

  const firstPage = () => setPage(1);

  const pagesArray = Array(fliteredOrdersData?.pages)
    .fill()
    .map((_, index) => index + 1);

  useEffect(() => {
    if (
      fliteredOrdersData !== null &&
      fliteredOrdersData !== undefined &&
      Object.keys(fliteredOrdersData).length > 0
    ) {
      if (fliteredOrdersData?.pages === 1) {
        // Extract the object keys as an array
        let dataKeys = Object.keys(fliteredOrdersData).filter(
          (key) => key !== "pages"
        );

        // Sort based on the sortOrder (latest or oldest)
        if (selectedSortType === "latest") {
          dataKeys.sort((a, b) => b - a); // Descending order for latest
        } else if (selectedSortType === "oldest") {
          dataKeys.sort((a, b) => a - b); // Ascending order for oldest
        }

        // Map the sorted keys back to the original data
        const sortedData = dataKeys.map((key) => ({
          id: key,
          ...fliteredOrdersData[key],
        }));

        setModifiedData(sortedData);
      } else {
        let dataKeys = Object.keys(fliteredOrdersData).filter(
          (key) => key !== "pages"
        );

        // Map the sorted keys back to the original data
        const sortedData = dataKeys.map((key) => ({
          id: key,
          ...fliteredOrdersData[key],
        }));
        setModifiedData(sortedData);
      }
    }
  }, [fliteredOrdersData, selectedSortType]);

  useEffect(() => {
    if (selectSortType !== "") {
      setSelectedSortType(selectSortType);
    }
  }, [selectSortType]);

  useEffect(() => {
    if (
      selectedFilterOptions?.length > 0 ||
      selectedOrderDate !== "" ||
      selectedOrderId !== "" ||
      selectedShopId !== "" ||
      selectedCustomerName !== "" ||
      selectedProductName !== "" ||
      selectedSortType !== "" ||
      selectSortType !== ""
    ) {
      refetchFilteredOrdersData();
    }
  }, [
    selectedFilterOptions,
    selectedOrderDate,
    selectedOrderId,
    selectedShopId,
    selectedCustomerName,
    selectedProductName,
    selectedSortType,
    selectSortType,
  ]);

  // console.log(fliteredOrdersData);
  // console.log(modifiedData);
  // console.log(selectSortType);
  // console.log(selectedSortType);

  if (isFliteredOrdersLoading) {
    return <LoadingModal />;
  }

  if (fliteredOrdersData?.pages === 0) {
    return <p>No data available..</p>;
  }

  return (
    <div>
      {modifiedData?.length > 0 ? (
        <>
          <div className=" flex items-end mb-2 ">
            <div className="flex flex-col justify-end  items-end  ">
              {/* <p className="text-gray-700 font-semibold text-xs m-1">Sort by</p> */}
              <select
                className="cursor-pointer w-32 md:w-full focus:outline-none py-1 px-2 bg-gradient-to-b from-purple-200 to-pink-300 rounded-full text-xs text-gray-600 shadow-md"
                required
                value="select "
                onChange={(e) => {
                  setSelectSortType(e.target.value);
                  setSelectedSortType(e.target.value);
                }}
              >
                <option value="select">Sort by</option>
                {[
                  { sm: "Latest First", val: "latest" },
                  { sm: "Oldest Fisrt", val: "oldest" },
                ].map((item, index) => (
                  <option
                    key={index}
                    className={` ${
                      selectedSortType === item.val ? "bg-pink-300" : ""
                    }`}
                    value={item?.val}
                  >
                    {item?.sm}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" p-2 w-full">
            <div className="flex justify-center space-x-1 text-gray-900">
              <button
                onClick={firstPage}
                disabled={isFilteredPrevOrdersData || page === 1}
                title="previous"
                type="button"
                className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path d="M13.9142 12.0001L18.7071 7.20718L17.2929 5.79297L11.0858 12.0001L17.2929 18.2072L18.7071 16.793L13.9142 12.0001ZM7 18.0001V6.00008H9V18.0001H7Z"></path>
                </svg>
              </button>
              {pagesArray.map((pg) => (
                <button
                  key={pg}
                  type="button"
                  disabled={isFilteredPrevOrdersData}
                  className={` ${
                    pg === page ? "bg-pink-300" : ""
                  } inline-flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-pink-300`}
                  onClick={() => setPage(pg)}
                >
                  {pg}
                </button>
              ))}
              <button
                onClick={lastPage}
                disabled={
                  isFilteredPrevOrdersData || page === fliteredOrdersData?.pages
                }
                title="next"
                type="button"
                className="inline-flex items-center justify-center w-6 h-6 py-0 border rounded-md shadow-md  border-pink-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4"
                >
                  <path d="M10.0859 12.0001L5.29297 16.793L6.70718 18.2072L12.9143 12.0001L6.70718 5.79297L5.29297 7.20718L10.0859 12.0001ZM17.0001 6.00008L17.0001 18.0001H15.0001L15.0001 6.00008L17.0001 6.00008Z"></path>
                </svg>
              </button>
            </div>
          </div>
          {isFilteredOrdersDataFetching && <p>Loading...</p>}
          <p className="flex flex-wrap gap-2 p-1">
            {selectedFilterOptions?.map((item, index) => (
              <span key={index} className="text-xxs">
                {" "}
                {'"'}
                {item.value}
                {'"'}
              </span>
            ))}
            {selectedOrderDate !== "" && (
              <span className="text-xxs">
                {'"'}
                {selectedOrderDate}
                {'"'}
              </span>
            )}
            {selectedOrderId !== "" && (
              <span className="text-xxs">
                {'"'}
                {selectedOrderId}
                {'"'}
              </span>
            )}
            {selectedShopId !== "" && (
              <span className="text-xxs">
                {'"'}
                {selectedShopId}
                {'"'}
              </span>
            )}
            {selectedCustomerName !== "" && (
              <span className="text-xxs">
                {'"'}
                {selectedCustomerName}
                {'"'}
              </span>
            )}
            {selectedProductName !== "" && (
              <span className="text-xxs">
                {'"'}
                {selectedProductName}
                {'"'}
              </span>
            )}
          </p>

          {modifiedData.map((order) => (
            <OrderCards key={order.order_id} productDetails={order} />
          ))}
        </>
      ) : (
        <p className="text-gray-500 text-center mt-24">No data available</p>
      )}
    </div>
  );
}

export default FilterComponent;
