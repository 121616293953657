import React, { useEffect, useState } from "react";
import IconComponent from "../Utility/IconComponent";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import getUsername from "../Account_Infomation/Username";
import getToken from "../Token";
import Modal from "react-modal";
import "../../styles/PostCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { RadioGroup } from "@headlessui/react";
import CheckoutForm from "./CheckoutForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import e from "cors";
import AttributeComponent from "./sharePage/AttributeComponent";
import { useQuery } from "react-query";
import Endpoints from "../../api/endpoints";
import ZaplynLogo from "../../assets/ZaplynLogo.svg";
import CustomerNote from "./NoteModal";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ShareFeed = (props) => {
  const [selectedattr1, setSelectedAttr1] = useState("null");
  const [selectedattr2, setSelectedAttr2] = useState("null");
  const [selectedattr3, setSelectedAttr3] = useState("null");
  const [selectedattr4, setSelectedAttr4] = useState("null");
  const [selectedattr5, setSelectedAttr5] = useState("null");
  const [category, setCategory] = useState("");

  const [itemName, setItemName] = useState("");
  const [qtyOption, setQtyOption] = useState([]);
  const [images, setImages] = useState([]);
  const [body, setBody] = useState("");
  const [body2, setBody2] = useState("");
  const [totalprice, setTotalprice] = useState(0);
  const [qty, setQty] = useState("");
  const [desc, setDesc] = useState("");
  const [fetched, setFetched] = useState("no");
  const [checked, setChecked] = useState(false);
  const [link_enabled, setLink_enabled] = useState("yes");
  const [chosencurrency, setChosencurrency] = useState("GBP");
  const [chosenqty, setChosenqty] = useState("1");
  const [addsent, setAddsent] = useState("no");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [email, setEmail] = useState("");
  const [deleted, setDeleted] = useState("no");
  const [cs, setCs] = useState("");

  const options = {
    clientSecret: cs,

    appearance: {
      /*...*/
    },
  };

  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const status = params.get("redirect_status");
  const [status, setStatus] = useState("");
  const [tile, setTile] = useState(useParams()["tile"]);
  const [qty2, setQty2] = useState(useParams()["qty"]);
  if (tile.slice(9, 10) && status != "succeeded") {
    setStatus("succeeded");
  }

  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);
  //error validation
  const [errorMessage, setErrorMessage] = useState("");

  //fetch categories
  const {
    isLoading: isCategoryLoading,
    data: productCategories,
    isError: isCategoryError,
  } = useQuery("categoryData", Endpoints.GetCategories, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  //variants
  const [showVariant, setShowVariant] = useState(false);
  const [variants, setVariants] = useState(null);
  const [variantPrice, setVariantPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("null");

  //addons
  const [showAddOn, setShowAddOn] = useState(false);
  const [addOns, setAddOns] = useState(null);
  const [availableAddOn, setAvailableAddOn] = useState(null);
  const [selectedAddOn, setSelectedAddOn] = useState([]);

  // estimated delivery
  const [selectedEtmDelivery, setSelectedEtmDelivery] = useState("");
  const [fromEtmDelivery, setFromEtmDelivery] = useState("");
  const [toEtmDelivery, setToEtmDelivery] = useState("");

  // Category and attribute related Func ---START---
  //*********----------********** */

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [attributeValues, setAttributeValues] = useState(null);

  const [selectedattribute1, setSelectedAttribute1] = useState([]);
  const [selectedattribute2, setSelectedAttribute2] = useState([]);
  const [selectedattribute3, setSelectedAttribute3] = useState([]);
  const [selectedattribute4, setSelectedAttribute4] = useState([]);
  const [selectedattribute5, setSelectedAttribute5] = useState([]);

  const [attribute1, setAttribute1] = useState("null");
  const [attribute2, setAttribute2] = useState("null");
  const [attribute3, setAttribute3] = useState("null");
  const [attribute4, setAttribute4] = useState("null");
  const [attribute5, setAttribute5] = useState("null");

  const [attribute1Error, setAttribute1Error] = useState(false);
  const [attribute2Error, setAttribute2Error] = useState(false);
  const [attribute3Error, setAttribute3Error] = useState(false);
  const [attribute4Error, setAttribute4Error] = useState(false);
  const [attribute5Error, setAttribute5Error] = useState(false);

  //*********----------********** */
  // Category and attribute related Func ---END---selectedVariant

  const [customerNote, setCustomerNote] = useState("");

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  const setNoteModalisOpenToFalse = () => {
    setIsNoteModalOpen(false);
  };

  // variant price change Onclick()
  const handlePriceChange = (variant) => {
    setSelectedVariant(variant);
    setVariantPrice(parseInt(variants[variant]));
    setTotalprice(parseInt(variants[variant]) + body2);
  };

  // Addon change Onclick()
  const handleAddOnChange = (addon) => {
    const newSelected = [...selectedAddOn];

    if (newSelected.includes(addon)) {
      newSelected.splice(newSelected.indexOf(addon), 1);
    } else {
      newSelected.push(addon);
    }
    setSelectedAddOn(newSelected);
  };

  //checkout button func
  const handlePurchase = async () => {
    let attr1Err = false;
    let attr2Err = false;
    let attr3Err = false;
    let attr4Err = false;
    let attr5Err = false;
    if (selectedattribute1.length > 0) {
      if (selectedattribute1.length === 1 && selectedattribute1[0] === "") {
        console.log("");
      } else if (selectedattr1 === "null") {
        attr1Err = true;
        setAttribute1Error(true);
      }
    }
    if (selectedattribute2.length > 0) {
      if (selectedattribute2.length === 1 && selectedattribute2[0] === "") {
        console.log("");
      } else if (selectedattr2 === "null") {
        attr2Err = true;
        setAttribute2Error(true);
      }
    }
    if (selectedattribute3.length > 0) {
      if (selectedattribute3.length === 1 && selectedattribute3[0] === "") {
        console.log("");
      } else if (selectedattr3 === "null") {
        attr3Err = true;
        setAttribute3Error(true);
      }
    }
    if (selectedattribute4.length > 0) {
      if (selectedattribute4.length === 1 && selectedattribute4[0] === "") {
        console.log("");
      } else if (selectedattr4 === "null") {
        attr4Err = true;
        setAttribute4Error(true);
      }
    }
    if (selectedattribute5.length > 0) {
      if (selectedattribute5.length === 1 && selectedattribute5[0] === "") {
        console.log("");
      } else if (selectedattr5 === "null") {
        attr5Err = true;
        setAttribute5Error(true);
      }
    }

    if (attr1Err || attr2Err || attr3Err || attr4Err || attr5Err) {
      console.log("Error");
    } else {
      try {
        if (addOns !== null) {
          var addonprice = Object.values(addOns).reduce((acc, value) => {
            return acc + parseInt(value);
          }, 0);
        }

        window.dataLayer.push({
          order_date: dayjs(Date.now()),
          order_amount: parseInt(totalprice) * parseInt(chosenqty) + addonprice,
          product_id: tile.slice(0, 9),
          product_name: itemName,
          event: "checkout",
        });
        const response = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}` +
            "create-payment-intent/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              qty: chosenqty,
              totalprice:
                parseInt(totalprice) * parseInt(chosenqty) + addonprice,
              ZaplynTileRef: tile.slice(0, 9),
            }),
          }
        );
        const json = await response.json();
        if (json) {
          setCs(json);
          setIsCheckoutClicked(true);
        }
      } catch (error) {
        console.log(error, "Something went wrong!");
      }
    }
  };

  const [errorMsg, setErrorMsg] = useState("");
  // fetch product data
  useEffect(() => {
    if (productCategories) {
      (async () => {
        try {
          const response = await fetch(
            `https://omnistack.org${process.env.REACT_APP_LINK}share-tile/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ZaplynTileRef: tile.slice(0, 9),
              }),
            }
          );

          const json = await response.json();
          // console.log(json);
          if (json) {
            if (json?.deleted === "no") {
              setDeleted("no");
              setFetched("yes");
              setSelectedCategory(json?.category);
              setSelectedSubCategory(json?.sub_category);
              if (json?.attribute_1 !== "") {
                setAttribute1(json?.attribute_1?.split("-")[0]);
                setSelectedAttribute1(
                  json?.attribute_1?.split("-")[1].split(",")
                );
              }
              if (json?.attribute_2 !== "") {
                setAttribute2(json?.attribute_2?.split("-")[0]);
                setSelectedAttribute2(
                  json?.attribute_2?.split("-")[1].split(",")
                );
              }
              if (json?.attribute_3 !== "") {
                setAttribute3(json?.attribute_3?.split("-")[0]);
                setSelectedAttribute3(
                  json?.attribute_3?.split("-")[1].split(",")
                );
              }
              if (json?.attribute_4 !== "") {
                setAttribute4(json?.attribute_4?.split("-")[0]);
                setSelectedAttribute4(
                  json?.attribute_4?.split("-")[1].split(",")
                );
              }
              if (json?.attribute_5 !== "") {
                setAttribute5(json?.attribute_5?.split("-")[0]);
                setSelectedAttribute5(
                  json?.attribute_5?.split("-")[1].split(",")
                );
              }
              setItemName(json?.itemname);
              setChosencurrency(json?.currency);
              setDesc(json?.description);
              setLink_enabled(json?.link_enabled);
              setImages(json?.images);

              setShowVariant(json?.show_variant);
              setVariants(json?.variant);

              setBody(json?.retail_price);
              setBody2(json?.shipping_price);
              if (json?.show_variant === true) {
                setSelectedVariant(Object.keys(json?.variant)[0]);
                setVariantPrice(parseInt(Object.values(json?.variant)[0]));
                setTotalprice(
                  parseInt(Object.values(json?.variant)[0]) +
                    json?.shipping_price
                );
              } else {
                setTotalprice(json?.total_price);
              }

              if (json?.add_ons !== null) {
                setShowAddOn(true);
                setAvailableAddOn(json?.add_ons[json.ZaplynTileRef]);
              }

              setQty(json?.quantity);
              if (json?.etm_date !== null) {
                setFromEtmDelivery(json?.etm_date?.split(",")[0]);
                setToEtmDelivery(json?.etm_date?.split(",")[1]);
                setSelectedEtmDelivery(json?.etm_date?.split(",")[2]);
              } else {
                setSelectedEtmDelivery(json?.etm_date);
              }
              if (json?.link_enabled === "yes") {
                setChecked(true);
              }
            } else {
              setDeleted("yes");
            }
          }
        } catch (error) {
          console.log("something went wrong", error);
          setErrorMsg(error.message);
        }
      })();
    }
  }, [productCategories]);

  useEffect(() => {
    // console.log("running");
    const divs = [];
    for (let i = 0; i < qty; i++) {
      divs.push(i + 1);
    }
    setQtyOption(divs);
  }, [qty]);

  useEffect(() => {
    if (!isCategoryLoading && selectedCategory && selectedSubCategory) {
      const subcategoryObj = productCategories[selectedCategory];
      let attributes;
      if (selectedSubCategory === "select") {
        attributes = subcategoryObj[selectedCategory];
      } else {
        attributes = subcategoryObj[selectedSubCategory];
      }
      setAttributeValues(attributes);
    }
  }, [selectedSubCategory]);

  // console.log("categ", selectedCategory);
  // console.log("sub-categ", selectedSubCategory);
  // console.log("ats", attributeValues);
  // console.log("at1", attribute1);
  // console.log("at2", attribute2);
  // console.log("at3", attribute3);
  // console.log("at4", attribute4);
  // console.log("at5", attribute5);

  // console.log("selectedatrr", selectedattribute1);
  // console.log("selectedatrr", selectedattribute2);
  // console.log("selectedatrr", selectedattribute3);
  // console.log("selectedatrr", selectedattribute4);
  // console.log("selectedatrr", selectedattribute5);

  useEffect(() => {
    setAddOns(
      selectedAddOn?.reduce((acc, item) => {
        acc[item] = availableAddOn[item];
        return acc;
      }, {})
    );
  }, [selectedAddOn]);

  if (errorMsg) {
    return (
      <p className="flex items-center justify-center max-w-xl mx-auto text-center mt-10 font-lexand">
        The Product you're looking for is Currently Unavailable!. Please try
        again later. Thankyou!
      </p>
    );
  }

  // console.log(toEtmDelivery, selectedEtmDelivery);

  return (
    <section>
      <ToastContainer />
      <Helmet>
        <title>{itemName}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={desc} />
        <meta property="og:title" content={itemName} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={images[0]} />
        <meta property="og:url" content={`https://www.zaplyn.link/${tile}`} />
        <link rel="canonical" href={`https://www.zaplyn.link/${tile}`} />
      </Helmet>
      <div className="font-lexand flex flex-col items-center justify-center w-full mt-1 ">
        <div className="flex items-center justify-end w-full max-w-3xl mr-2 md:mr-10">
          <p className="text-xxs">powered by</p>
          <img src={ZaplynLogo} alt="" className=" h-4" />
        </div>
        <div className="flex items-center w-full md:max-w-3xl mt-3">
          <p className="mx-8 text-xs tracking-tight text-gray-400 ">
            {selectedCategory} {` > `}
            {selectedSubCategory === "select" ? "" : `${selectedSubCategory} >`}
          </p>
        </div>

        <div>
          {checked && deleted === "no" && (
            <div className=" rounded-md m-1 p-0.5 transform transition-all duration-700 ease-in-out">
              {!isCheckoutClicked ? (
                <div className="mx-auto max-w-xl px-4 pt-2 pb-16 sm:px-6 md:grid md:max-w-3xl md:grid-cols-4 md:grid-rows-[auto,auto,1fr] md:gap-x-8 md:px-8 md:pb-24">
                  {/* Description and details */}
                  <div className=" md:col-span-2 md:col-start-1  md:border-r md:border-gray-200 ">
                    <div className="relative p-2 text-5 w-full mb-2 ">
                      <Carousel
                        infiniteLoop={true}
                        showStatus={false}
                        showArrows={false}
                        swipeable={false}
                        thumbWidth={50}
                        className="postproductCarousel"
                      >
                        {images.map((image) => (
                          <div key={image}>
                            <img src={image} alt="" className="" />
                          </div>
                        ))}
                      </Carousel>
                      <p className="hidden absolute top-4 left-4 text-gray-300 bg-yellow-100 text-sm font-serif  rounded-md  p-1 ">
                        Product Details
                      </p>
                    </div>
                    <h2 className="sr-only">Product information</h2>

                    <div className="mt-1">
                      <div className="md:col-span-2 md:border-r md:border-gray-200 md:pr-8">
                        <h1 className=" text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                          {itemName}
                        </h1>
                      </div>

                      <div className="mt-2 space-y-6">
                        <p className="text-sm text-gray-600">{desc}</p>
                      </div>
                    </div>
                  </div>

                  {/* Options */}
                  <div className=" md:row-span-3 md:col-span-2 md:mt-0 p-2">
                    {/* Price flex-div */}
                    <div className="flex justify-between">
                      <p className="text-2xl tracking-tight text-gray-900">
                        {chosencurrency === "GBP" && (
                          <span className="mr-1">£</span>
                        )}
                        {chosencurrency === "EUR" && (
                          <span className="mr-1">€</span>
                        )}
                        {chosencurrency === "USD" && (
                          <span className="mr-1">$</span>
                        )}
                        {parseInt(totalprice) * parseInt(chosenqty)}
                      </p>
                      <div>
                        {showVariant ? (
                          <p className="text-xs tracking-tight text-gray-500">
                            Price: {variantPrice} {chosencurrency}
                          </p>
                        ) : (
                          <p className="text-xs tracking-tight text-gray-500">
                            Price: {body} {chosencurrency}
                          </p>
                        )}
                        {body2 !== 0 && (
                          <p className="text-xs tracking-tight text-gray-500">
                            Shipping Price: {body2} {chosencurrency}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* product variants */}
                    {showVariant && (
                      <div className="flex flex-wrap gap-2 mt-5 items-center ">
                        {Object.keys(variants).map((variantName) => (
                          <div key={variantName}>
                            <div
                              className={`${
                                variantName === selectedVariant
                                  ? "bg-gradient-to-b from-purple-200 to-pink-300"
                                  : "bg-gray-100"
                              } text-sm p-0.5 rounded-xl`}
                            >
                              <button
                                onClick={() => handlePriceChange(variantName)}
                                className={`bg-white p-1 px-4 rounded-xl focus:outline-none`}
                              >
                                {variantName}
                              </button>
                            </div>
                            <p className="text-center text-xs text-gray-500">
                              {chosencurrency === "GBP" && (
                                <span className="mr-1">£</span>
                              )}
                              {chosencurrency === "EUR" && (
                                <span className="mr-1">€</span>
                              )}
                              {chosencurrency === "USD" && (
                                <span className="mr-1">$</span>
                              )}
                              {parseInt(variants[variantName])}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}

                    <form className="mt-5">
                      {attributeValues !== null && (
                        <div className="">
                          {/* ----------**********---------- */}
                          {/* attribute-1 */}
                          {/* ----------**********---------- */}
                          {selectedattribute1?.length > 0 &&
                          attribute1 === "color" ? (
                            // color
                            <>
                              {selectedattribute1?.length > 0 &&
                              selectedattribute1[0] !== "" ? (
                                <div>
                                  <h3 className="text-sm font-medium text-gray-900">
                                    Color
                                  </h3>
                                  {attribute1Error && (
                                    <p className="text-red-500 text-xxs">
                                      please Select
                                    </p>
                                  )}

                                  <RadioGroup
                                    value={selectedattr1}
                                    onChange={setSelectedAttr1}
                                    className="mt-4 text-xs"
                                  >
                                    <RadioGroup.Label className="sr-only">
                                      {" "}
                                      Choose a color{" "}
                                    </RadioGroup.Label>
                                    <div className="flex items-center space-x-3">
                                      {/* {productDetails.color.split(",").map((color) => ( */}

                                      {selectedattribute1.map((color) => (
                                        <RadioGroup.Option
                                          key={color}
                                          value={color}
                                          className={({ active, checked }) =>
                                            classNames(
                                              color.selectedClass,
                                              active && checked
                                                ? "ring ring-offset-1"
                                                : "",
                                              !active && checked
                                                ? "ring-2"
                                                : "",
                                              "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                            )
                                          }
                                        >
                                          <RadioGroup.Label
                                            as="span"
                                            className="sr-only"
                                          >
                                            {" "}
                                            {color}{" "}
                                          </RadioGroup.Label>
                                          <span
                                            aria-hidden="true"
                                            style={{
                                              backgroundColor: color,
                                            }}
                                            className={classNames(
                                              color,
                                              "h-8 w-8 rounded-full border border-black border-opacity-10"
                                            )}
                                          />
                                        </RadioGroup.Option>
                                      ))}
                                    </div>
                                  </RadioGroup>
                                </div>
                              ) : (
                                <>
                                  {selectedattribute1?.length > 1 && (
                                    <div>
                                      <h3 className="text-sm font-medium text-gray-900">
                                        Color
                                      </h3>
                                      {attribute1Error && (
                                        <p className="text-red-500 text-xxs">
                                          please Select
                                        </p>
                                      )}

                                      <RadioGroup
                                        value={selectedattr1}
                                        onChange={setSelectedAttr1}
                                        className="mt-4 text-xs"
                                      >
                                        <RadioGroup.Label className="sr-only">
                                          {" "}
                                          Choose a color{" "}
                                        </RadioGroup.Label>
                                        <div className="flex items-center space-x-3">
                                          {/* {productDetails.color.split(",").map((color) => ( */}

                                          <>
                                            {selectedattribute1
                                              .slice(1)
                                              .map((color) => (
                                                <RadioGroup.Option
                                                  key={color}
                                                  value={color}
                                                  className={({
                                                    active,
                                                    checked,
                                                  }) =>
                                                    classNames(
                                                      color.selectedClass,
                                                      active && checked
                                                        ? "ring ring-offset-1"
                                                        : "",
                                                      !active && checked
                                                        ? "ring-2"
                                                        : "",
                                                      "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                                    )
                                                  }
                                                >
                                                  <RadioGroup.Label
                                                    as="span"
                                                    className="sr-only"
                                                  >
                                                    {" "}
                                                    {color}{" "}
                                                  </RadioGroup.Label>
                                                  <span
                                                    aria-hidden="true"
                                                    style={{
                                                      backgroundColor: color,
                                                    }}
                                                    className={classNames(
                                                      color,
                                                      "h-8 w-8 rounded-full border border-black border-opacity-10"
                                                    )}
                                                  />
                                                </RadioGroup.Option>
                                              ))}
                                          </>
                                        </div>
                                      </RadioGroup>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {selectedattribute1?.length > 0 && (
                                <>
                                  {selectedattribute1.length === 1 &&
                                  selectedattribute1[0] === "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <AttributeComponent
                                        attributeName={attribute1}
                                        attributeErr={attribute1Error}
                                        selectedAttribute={selectedattr1}
                                        setSelectedAttribute={setSelectedAttr1}
                                        attributeValues={
                                          attributeValues[attribute1]
                                        }
                                        availableAttribute={selectedattribute1}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-2 */}
                          {/* ----------**********---------- */}
                          {selectedattribute2?.length > 0 && (
                            <>
                              {selectedattribute2.length === 1 &&
                              selectedattribute2[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute2}
                                    attributeErr={attribute2Error}
                                    selectedAttribute={selectedattr2}
                                    setSelectedAttribute={setSelectedAttr2}
                                    attributeValues={
                                      attributeValues[attribute2]
                                    }
                                    availableAttribute={selectedattribute2}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-3*/}
                          {/* ----------**********---------- */}
                          {selectedattribute3?.length > 0 && (
                            <>
                              {selectedattribute3.length === 1 &&
                              selectedattribute3[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute3}
                                    attributeErr={attribute3Error}
                                    selectedAttribute={selectedattr3}
                                    setSelectedAttribute={setSelectedAttr3}
                                    attributeValues={
                                      attributeValues[attribute3]
                                    }
                                    availableAttribute={selectedattribute3}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-4 */}
                          {/* ----------**********---------- */}
                          {selectedattribute4?.length > 0 && (
                            <>
                              {selectedattribute4.length === 1 &&
                              selectedattribute4[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute4}
                                    attributeErr={attribute4Error}
                                    selectedAttribute={selectedattr4}
                                    setSelectedAttribute={setSelectedAttr4}
                                    attributeValues={
                                      attributeValues[attribute4]
                                    }
                                    availableAttribute={selectedattribute4}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* ----------**********---------- */}
                          {/* attribute-5 */}
                          {/* ----------**********---------- */}
                          {selectedattribute5?.length > 0 && (
                            <>
                              {selectedattribute5.length === 1 &&
                              selectedattribute5[0] === "" ? (
                                <></>
                              ) : (
                                <>
                                  <AttributeComponent
                                    attributeName={attribute5}
                                    attributeErr={attribute5Error}
                                    selectedAttribute={selectedattr5}
                                    setSelectedAttribute={setSelectedAttr5}
                                    attributeValues={
                                      attributeValues[attribute5]
                                    }
                                    availableAttribute={selectedattribute5}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </form>

                    {/* quantity */}
                    {status !== "succeeded" && (
                      <div className="flex items-start mt-5 justify-start gap-5">
                        <div className="flex justify-around items-center gap-2 w-full">
                          <p className="text-gray-700">Quantity</p>{" "}
                          {qty === 0 && (
                            <p className="text-gray-400 tex-sm">
                              Currently Unavailable
                            </p>
                          )}
                          <select
                            name="qty"
                            className="focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 w-full cursor-pointer rounded-lg bg-gray-200"
                            id="qty"
                            required
                            onChange={(e) => setChosenqty(e.target.value)}
                          >
                            {qtyOption.map((qty) => (
                              <option value={qty}>{qty}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {/* Add-ons div */}
                    {showAddOn && (
                      <div className=" mt-2 ">
                        {availableAddOn !== null &&
                          availableAddOn !== undefined &&
                          Object.keys(availableAddOn).length > 0 &&
                          !Object.keys(availableAddOn).some(
                            (key) => key === ""
                          ) && (
                            <>
                              <p className="text-gray-600 ">Add-ons -</p>
                              <div className="flex flex-wrap gap-2 mt-1 items-center">
                                {Object.keys(availableAddOn).map((addon) => (
                                  <div key={addon}>
                                    <div
                                      className={`${
                                        selectedAddOn.includes(addon)
                                          ? "bg-gradient-to-b from-purple-200 to-pink-300"
                                          : "bg-gray-100"
                                      } text-sm p-0.5 rounded-xl`}
                                    >
                                      <button
                                        onClick={() => handleAddOnChange(addon)}
                                        className={`bg-white p-1 px-4 rounded-xl focus:outline-none`}
                                      >
                                        {addon}
                                      </button>
                                    </div>
                                    <p className="text-center text-xs text-gray-500">
                                      {!isNaN(
                                        parseInt(availableAddOn[addon])
                                      ) && <span className="mr-1">£</span>}
                                      {isNaN(parseInt(availableAddOn[addon]))
                                        ? ""
                                        : parseInt(availableAddOn[addon])}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                    )}

                    {/* Add note */}
                    <div className="mt-5">
                      <button
                        onClick={() => setIsNoteModalOpen(true)}
                        className={`flex items-center text-xs   `}
                      >
                        {customerNote === "" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-4"
                          >
                            <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-4"
                          >
                            <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                          </svg>
                        )}
                        {customerNote !== "" ? "View Note" : "Add Note:"}{" "}
                      </button>
                    </div>
                    {selectedEtmDelivery !== null &&
                      selectedEtmDelivery !== "" &&
                      selectedEtmDelivery !== "select" && (
                        <div className=" mt-8">
                          <p className="flex gap-2 text-sm items-center justify-end text-gray-700">
                            Estimated Delivery:{" "}
                            <span className="text-xs text-gray-500">
                              {fromEtmDelivery}
                            </span>
                            -
                            <span className="text-xs text-gray-500">
                              {toEtmDelivery}
                            </span>
                            <span className="text-xs text-gray-500">
                              {selectedEtmDelivery}
                            </span>
                          </p>
                        </div>
                      )}

                    <button
                      className=" mt-2 flex w-full items-center justify-center rounded-full py-3 px-8 text-base font-medium text-gray-700 bg-gradient-to-b from-purple-200 to-pink-300"
                      onClick={() => {
                        handlePurchase();
                      }}
                    >
                      Continue to Checkout
                    </button>
                    <p className="text-red-600 text-xs text-center mt-2 animate-pulse">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-between space-x-2 sm:space-x-4 p-2 m-1 rounded-lg shadow-lg  ">
                  <div className="relative p-2 text-5 w-24 mb-2 ">
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showArrows={false}
                      swipeable={false}
                      showThumbs={false}
                      thumbWidth={50}
                      className="postproductCarousel"
                    >
                      {images.map((image) => (
                        <div key={image}>
                          <img src={image} alt="" className="" />
                        </div>
                      ))}
                    </Carousel>
                    <p className="hidden absolute top-4 left-4 text-gray-300 bg-yellow-100 text-sm font-serif  rounded-md  p-1 ">
                      Product Details
                    </p>
                  </div>
                  <div className="flex flex-col justify-between w-full pb-4">
                    <div className="flex justify-between w-full pb-2 space-x-2">
                      <div className="space-y-1">
                        <h3 className="text-lg font-semibold leading-snug sm:pr-8">
                          {itemName}
                        </h3>
                        <p className="text-sm dark:text-gray-400 w-40 truncate">
                          {desc}
                        </p>
                      </div>
                      <div className="text-right">
                        {addOns !== null ? (
                          <>
                            £{" "}
                            {Object.values(addOns).reduce((acc, value) => {
                              return acc + parseInt(value);
                            }, 0) +
                              parseInt(totalprice) * parseInt(chosenqty)}
                          </>
                        ) : (
                          <>
                            <p className="text-lg font-semibold">
                              £ {parseInt(totalprice) * parseInt(chosenqty)}£
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between w-full pb-2 space-x-2">
                      <div className="space-y-1">
                        <p className="text-xxs md:text-xs font-semibold leading-snug sm:pr-8">
                          {chosenqty}{" "}
                          {selectedVariant !== "null" && selectedVariant}{" "}
                          {selectedattr1 !== "null" &&
                            attribute1 !== "color" &&
                            selectedattr1.toString()}{" "}
                          {selectedattr2 !== "null" && selectedattr2.toString()}{" "}
                          {selectedattr3 !== "null" && selectedattr3.toString()}{" "}
                          {selectedattr4 !== "null" && selectedattr4.toString()}{" "}
                          {selectedattr5 !== "null" && selectedattr5.toString()}{" "}
                        </p>
                      </div>
                    </div>

                    <div className="flex text-sm divide-x">
                      <button
                        onClick={() => setIsCheckoutClicked(false)}
                        type="button"
                        className="flex items-center px-4 py-1 pl-0 space-x-1 rounded-lg bg-gradient-to-b from-purple-200 to-pink-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5 ml-1"
                        >
                          <path d="M9.24264 18.9964H21V20.9964H3V16.7538L12.8995 6.85431L17.1421 11.0969L9.24264 18.9964ZM14.3137 5.44009L16.435 3.31877C16.8256 2.92825 17.4587 2.92825 17.8492 3.31877L20.6777 6.1472C21.0682 6.53772 21.0682 7.17089 20.6777 7.56141L18.5563 9.68273L14.3137 5.44009Z"></path>
                        </svg>
                        <span>Edit</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Custopm Stripe payment form */}
        <div className="  mb-20 md:mt-2 max-w-xl">
          {cs !== "" && status !== "succeeded" && deleted === "no" && (
            <div className=" p-1">
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  tile={tile}
                  category={selectedCategory}
                  subcategory={selectedSubCategory}
                  attribute_1={`${attribute1}-${selectedattr1.toString()}`}
                  attribute_2={`${attribute2}-${selectedattr2.toString()}`}
                  attribute_3={`${attribute3}-${selectedattr3.toString()}`}
                  attribute_4={`${attribute4}-${selectedattr4.toString()}`}
                  attribute_5={`${attribute5}-${selectedattr5.toString()}`}
                  chosenqty={chosenqty}
                  itemName={itemName}
                  variant={selectedVariant}
                  addOns={addOns}
                  customerNote={customerNote}
                  price={parseInt(totalprice) * parseInt(chosenqty)}
                />
              </Elements>
            </div>
          )}
        </div>
        {(deleted === "yes" || link_enabled === "no") && (
          <div className="mx-auto max-w-xl px-4 pt-2 pb-16 sm:px-6 md:grid md:max-w-3xl md:grid-cols-4 md:grid-rows-[auto,auto,1fr] md:gap-x-8 md:px-8 md:pb-24">
            <div className="md:col-span-2 md:border-r md:border-gray-200 md:pr-8">
              <h1 className=" text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Product was removed or not available.
              </h1>
            </div>
          </div>
        )}
      </div>

      {/* customer note modal */}
      <Modal
        isOpen={isNoteModalOpen}
        className="font-lexand mt-10 max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsNoteModalOpen(false)}
      >
        <CustomerNote
          closeOnClick={setNoteModalisOpenToFalse}
          setUserNote={setCustomerNote}
          customerNote={customerNote}
        />
      </Modal>
    </section>
  );
};

export default ShareFeed;
