import React, { useState } from "react";
import { useQueryClient } from "react-query";
import getToken from "../Token";
import { createTileRef } from "../../helper/helper";

function TransferShopPopup({ tile, closeOnClick }) {
  const queryClient = useQueryClient();
  const [transferTo, setTransferTo] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  //   cancel button func
  const handleCancelClick = () => {
    setTransferTo("");
    setContactEmail("");
    setContactPhone("");
    closeOnClick();
  };

  //   save button func
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log(internalNote);

    const content = {
      shopRef: tile.ZaplynShopRef,
      username: transferTo,
      Name: tile.shopname,
      email: contactEmail,
      phoneNumber: contactPhone,
    };
    const accesstoken = getToken();
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}transfer-shop/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify(content),
      }
    );

    const res = await response.json();

    if (res) {
      await queryClient.invalidateQueries("shopsData");
    }

    closeOnClick();
  };

  return (
    <div>
      <div className="bg-white p-4 rounded-lg">
        <div className="flex flex-col gap-2 md:gap-4 ">
          <div className="">
            <label
              className="text-black flex text-xs "
              htmlFor="shopownerusername"
            >
              Username
            </label>
            <input
              id="shopownerusername"
              className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full text-left text-sm  `}
              type="Text"
              required
              value={transferTo}
              onChange={(e) => setTransferTo(e.target.value)}
              placeholder="Add username*"
              rows="1"
              maxLength={50}
            />{" "}
          </div>

          <div className="">
            <label
              className="text-black flex text-xs "
              htmlFor="shopowneremail"
            >
              Email
            </label>
            <input
              id="shopowneremail"
              className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full text-left text-sm  `}
              type="Text"
              required
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              placeholder="Add Email*"
              rows="1"
              maxLength={50}
            />
          </div>
          <div className="">
            <label
              className="text-black flex text-xs "
              htmlFor="showownercontact"
            >
              Contact
            </label>
            <input
              id="showownercontact"
              className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full text-left text-sm  `}
              type="Number"
              required
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
              placeholder="Add Contact*"
              rows="1"
              maxLength={50}
            />
          </div>
        </div>

        <div className="flex gap-2 mt-3 text-sm">
          <button
            onClick={handleCancelClick}
            className="px-6 py-0.5 rounded-full text-gray-700 border hover:bg-gray-100 "
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className=" cursor-pointer  px-6 py-0.5   bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferShopPopup;
