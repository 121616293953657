import React, { useState } from "react";
import { useQueryClient } from "react-query";
import getToken from "../Token";
import { createTileRef } from "../../helper/helper";

function TransferProductPopup({ tile, closeOnClick }) {
  const queryClient = useQueryClient();
  const [transferTo, setTransferTo] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  //   cancel button func
  const handleCancelClick = () => {
    setTransferTo("");
    setIsEditable(false);
    closeOnClick();
  };

  //   save button func
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log(internalNote);
    const newTileref = createTileRef();

    const content = {
      author: transferTo,
      attribute: tile.attribute,
      totalprice: tile.total_price,
      itemname: tile.itemname,
      desc: tile.description,
      body: tile.retail_price,
      body2: tile.shipping_price,
      color: tile.color,
      size: tile.size,
      qty: tile.quantity,
      images: tile.images.toString(),
      subject: tile.category,
      sub_category: tile.sub_category,
      ZaplynTileRef: newTileref,
      link_enabled: tile.link_enabled,
      variant: tile.variant,
      show_variant: tile.show_variant,
      add_ons: tile.add_ons,
      etm_date: tile.etm_date,
      currency: tile.currency,
      attribute_1: tile.attribute_1,
      attribute_2: tile.attribute_2,
      attribute_3: tile.attribute_3,
      attribute_4: tile.attribute_4,
      attribute_5: tile.attribute_5,
    };
    const accesstoken = getToken();
    const response = await fetch(
      `https://omnistack.org${process.env.REACT_APP_LINK}message-create/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify(content),
      }
    );

    const res = await response.json();

    if (res) {
      await queryClient.invalidateQueries(["productsData"]);
      await queryClient.invalidateQueries(["filteredProductsData"]);
      await queryClient.invalidateQueries("categoryFilterButtonsData");
    }

    closeOnClick();
  };

  return (
    <div>
      <div className="bg-white p-4 rounded-lg">
        <div className="flex flex-col gap-2 md:gap-4 ">
          <div className="">
            <label
              className="text-black flex text-xs "
              htmlFor="shopownerusername"
            >
              Username
            </label>
            <input
              id="shopownerusername"
              className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full text-left text-sm  `}
              type="Text"
              required
              value={transferTo}
              onChange={(e) => setTransferTo(e.target.value)}
              placeholder="Add username*"
              rows="1"
              maxLength={50}
            />{" "}
          </div>

          <div className="flex gap-2 text-sm">
            <button
              onClick={handleCancelClick}
              className="px-6 py-0.5 rounded-full text-gray-700 border hover:bg-gray-100 "
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" cursor-pointer  px-6 py-0.5   bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferProductPopup;
