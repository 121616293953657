import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ZaplynLogo from "../../assets/ZaplynLogo.svg";
import LineArrowRight from "../../assets/images/Line arrow-right.png";
import HeroImage from "../../assets/images/Hero Images.png";
import HeroImageTwo from "../../assets/images/Image_Online Shopping.png";
import Create from "../../assets/images/Create.png";
import Accept from "../../assets/images/Accept.png";
import Showcase from "../../assets/images/Showcase.png";
import Receive from "../../assets/images/Receive.png";

import ShopImage from "../../assets/images/Zaplyn Shop_Image.png";
import verifyIcon from "../../assets/images/verify.png";
import lightningIcon from "../../assets/images/lightning_fill.png";
import percentageICon from "../../assets/images/percentage-square.png";
import categoryIcon from "../../assets/images/category-2.png";
import tickIcon from "../../assets/images/tick-circle.png";
import BottomCardImage from "../../assets/images/BottomCardImage.png";

import X2Icon from "../../assets/ZaplynLogo.svg";
import StepOne from "../../assets/ZaplynLogo.svg";
import StepTwo from "../../assets/ZaplynLogo.svg";
import StepThree from "../../assets/ZaplynLogo.svg";
import One from "../../assets/ZaplynLogo.svg";
import Two from "../../assets/ZaplynLogo.svg";
import Three from "../../assets/ZaplynLogo.svg";
import ArrowOne from "../../assets/ZaplynLogo.svg";
import ArrowTwo from "../../assets/ZaplynLogo.svg";
import ArrowUp from "../../assets/ZaplynLogo.svg";
import ArrowDown from "../../assets/ZaplynLogo.svg";
import RegisterNow from "../../assets/ZaplynLogo.svg";
import Modal from "react-modal";

const FAQdata = [
  {
    q: "Can I join Puntville for free?",
    a: `Yes absolutely, joining Puntville is easy and completely free. Even better, when you sign up on our app, we give you free credits (called "coins") to create and or take bets.`,
  },
  {
    q: "How do I create an account and deposit money?",
    a: "You can sign up using our sign-up page here. It's a simple and fast process and only needs you to have access to your email. To buy coins, you can simply add a payment method in your 'Wallet' section and proceed to buy more coins with one click in 'Recharge' section.",
  },
  {
    q: "What types of bets can I place?",
    a: "This is the most fun part. You can create a bet on anything and everything under the sun. Let your imagination run wild- and make sure your friends get to know about it. ",
  },
  {
    q: "How can I share my bets with friends?",
    a: `Simply click on "Share bet" button on the tile and it will generate a sharing link for you. You can forward this link as a message, email or even let others scan QR code.`,
  },
  {
    q: "How do I withdraw my winnings?",
    a: "Bets are placed and settled using coins only. Your total balance (in coins) is visible on the main screen and currently we don't allow conversion of coins to cash. ",
  },
];
function App() {
  const navigate = useNavigate();
  const aboutRef = useRef(null);
  const priRef = useRef(null);
  const FAQsRef = useRef(null);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [faqIndex, setFaqIndex] = useState();

  const [subEmail, setSubEmail] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSubEmailValid, setIsSubEmailValid] = useState(true);

  const [isSuccessReg, setIsSuccessReg] = useState(false);
  const [isSuccessContact, setIsSuccessContact] = useState(false);

  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const validateEmail = () => {
    setIsEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) // regex for email validation
    );
  };

  const validateSubEmail = () => {
    setIsSubEmailValid(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(subEmail) // regex for email validation
    );
  };

  const handleFAQindexchange = (index) => {
    if (faqIndex === index) {
      setIsFaqOpen(false);
      setFaqIndex(-1);
    } else {
      setIsFaqOpen(false);
      setFaqIndex(index);
      setIsFaqOpen(true);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    validateSubEmail();

    var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(subEmail); // regex for email validation

    if (isValidEmail) {
      try {
        var data = {
          email: subEmail,
        };
        const response = await fetch(
          "https://4fr5ssfnjl.execute-api.eu-west-2.amazonaws.com/prod/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const json = await response.json();

        if (json) {
          setIsSuccessReg(true);
          setSubEmail("");
        }
        navigate("/register", { state: { useremail: subEmail } });
      } catch (error) {
        console.log("There was a SyntaxError", error);
      }
      const response = await fetch(
        "https://omnistack.org/Zaplyn/" + "send-register-email/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    }
  };

  const handleRegister2 = async (e) => {
    e.preventDefault();
    validateEmail();

    var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // regex for email validation

    if (isValidEmail) {
      try {
        var data = {
          email: email,
        };
        const response = await fetch(
          "https://4fr5ssfnjl.execute-api.eu-west-2.amazonaws.com/prod/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const json = await response.json();

        if (json) {
          setIsSuccessReg(true);
          setSubEmail("");
          navigate("/register", { state: { useremail: email } });
        }
      } catch (error) {
        console.log("There was a SyntaxError", error);
      }
      const response = await fetch(
        "https://omnistack.org/Zaplyn/" + "send-register-email/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    }
  };

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isSuccessReg) {
      setTimeout(() => setIsSuccessReg(false), 3000);
    }
    if (isSuccessContact) {
      setTimeout(() => setIsSuccessContact(false), 3000);
    }
  }, [isSuccessReg, isSuccessContact]);

  const handleAccordianToggle = (index) => {
    console.log("toggle");
    setIsFaqOpen(!isFaqOpen);
    setFaqIndex(index);
  };
  return (
    <section className="bg-[#f5f5f5] font-lexand">
      <section className=" font-lexand  text-[#450089]">
        {/* header */}
        <div className="bg-landingHeader shadow-md">
          <div className=" flex flex-col max-w-7xl mx-auto ">
            <div className="flex items-center justify-between px-5 xl:px-2 ">
              <div>
                <img
                  src={ZaplynLogo}
                  alt=""
                  className="w-40 xl:w-60  h-16 xl:h-20"
                />
              </div>
              <div className="hidden md:flex gap-5 text-[#450089] ">
                <button
                  onClick={() => handleClick(aboutRef)}
                  className="About  text-[18px] font-medium leading-none"
                >
                  About
                </button>
                <button
                  onClick={() => handleClick(priRef)}
                  className="HowItWorks  text-[18px] font-medium leading-none"
                >
                  Pricing
                </button>

                {/* <button
                  onClick={() => handleClick(FAQsRef)}
                  className="Faqs text-[18px] font-medium leading-none"
                >
                  FAQs
                </button> */}
              </div>
              <div className=" ">
                <Link to="/login">
                  <button className="text-xs px-4 py-1 md:py-2 lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white ">
                    Get started
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* hero  */}
        <div className="bg-landingHero pb-8">
          <div className=" flex flex-col max-w-7xl mx-auto ">
            <div className="flex flex-col md:flex-row items-center justify-between max-w-2xl lg:max-w-4xl xl:max-w-7xl mx-auto p-5 mt-5 gap-10 ">
              <div className="flex flex-col  md:w-[480px] lg:w-[600px] xl:w-[620px] ">
                <div className="flex items-center gap-2">
                  <img src={LineArrowRight} alt="" className="w-5 h-5 " />
                  <p className=" text-[#450089] rounded-xl text-center text-xs md:text-sm p-1">
                    All in-one platform
                  </p>
                </div>
                <h1 className="text-[#450089] text-[26px] md:text-[29px] lg:text-[42px] xl:text-5xl  py-2">
                  Create your{" "}
                  <span className="text-[#D53F8C]">online store</span> in a zap.
                </h1>
                <p className="hidden md:block text-xs md:text-sm xl:text-lg text-justify px-1 pr-2 text-[#450089]">
                  Build own online store without any engineering knowledge and
                  start selling your products today.
                </p>
                <div className="hidden md:flex items-center justify-start gap-2 mt-8 md:pr-16 xl:pr-40">
                  <Link to="/login">
                    <button
                      type="button"
                      onClick={() => {}}
                      className="text-xs px-4 py-2 lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white font-medium active:opacity-70"
                    >
                      Get started for free
                    </button>
                  </Link>
                </div>
              </div>

              <div className="">
                <img
                  src={HeroImage}
                  alt=""
                  className="max-w-xs md:max-w-[240px] lg:max-w-xs xl:max-w-sm"
                />
              </div>

              <div className="md:hidden flex flex-col items-start justify-start w-full gap-2 mt-4">
                <p className="text-xs md:text-sm xl:text-lg text-justify px-1 pr-2 text-[#450089]">
                  Build own online store without any engineering knowledge and
                  start selling your products today.
                </p>
                <Link to="/login">
                  <button
                    type="button"
                    onClick={() => {}}
                    className="text-xs px-4 py-2 mt-3 rounded-full bg-landingBtn text-white font-medium active:opacity-70"
                  >
                    Get started for free
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* details */}
      <section ref={aboutRef} className=" font-lexand bg-[#f5f5f5]">
        {/* get started in minute */}
        <div className=" flex flex-col max-w-7xl mx-auto ">
          <div className="flex items-center justify-between max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto p-5 mt-5 gap-10 ">
            <div className="flex flex-col md:w-[480px]  xl:w-[550px] ">
              <h1 className="text-[#450089] text-[20px] md:text-[24px] lg:text-[31px] xl:text-3xl  py-2">
                Start your e-commerce business on Zaplyn{" "}
                <span className="text-[#D53F8C]">in minutes</span>{" "}
              </h1>
              <p className="text-xs lg:text-sm xl:text-base text-justify px-1 pr-2 text-[#450089]">
                Do you want to sell your products online but don't know where to
                start? Create own e-commerce store on Zaplyn - all by yourself
                without any engineering knowledge.
              </p>
              <div className="flex items-center justify-start gap-2 mt-8 md:pr-16 xl:pr-40">
                <Link to="/login">
                  <button
                    type="button"
                    onClick={() => {}}
                    className="text-xs px-4 py-2 lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white font-medium active:opacity-70"
                  >
                    Get started
                  </button>
                </Link>
              </div>
            </div>

            <div className="hidden md:block  ">
              <img src={HeroImageTwo} alt="" className="max-w-xs shadow-md " />
            </div>
          </div>
        </div>

        {/* All the tools you need to sell online */}
        <div className="flex flex-col max-w-7xl mx-auto items-center py-10 text-[#450089]">
          <h3 className=" font-medium text-[20px] md:text-[26px] lg:text-[31px] xl:text-3xl ">
            All the tools you need to{" "}
            <span className="text-[#D53F8C]"> sell online</span>{" "}
          </h3>
          <p className="text-center p-2 text-xs lg:text-sm max-w-md xl:max-w-lg mx-auto py-5 lg:pb-8 ">
            From easy-to-use online store builder to payment transfers - Zaplyn
            provides all the features you need for running e-commerce business.
          </p>
          <div className="grid md:grid-cols-2  lg:grid-cols-4 gap-10 lg:gap-4   text-semibold text-[#450089]">
            <div className="flex flex-col bg-white rounded-md pb-5  text-center shadow-md">
              <img
                src={Create}
                alt=""
                className="w-80 lg:max-w-[230px] xl:max-w-[300px]  "
              />
              <p className="py-5 lg:text-xs">Create your online-shop </p>
            </div>
            <div className="flex flex-col bg-white rounded-md pb-5 text-center shadow-md">
              <img
                src={Showcase}
                alt=""
                className="w-80 lg:max-w-[230px] xl:max-w-[300px]  "
              />
              <p className="py-5 lg:text-xs">Showcase your products</p>
            </div>
            <div className="flex flex-col bg-white rounded-md pb-5 text-center shadow-md">
              <img
                src={Accept}
                alt=""
                className="w-80 lg:max-w-[230px] xl:max-w-[300px]   "
              />
              <p className="py-5 lg:text-xs">Accept and manage orders</p>
            </div>
            <div className="flex flex-col bg-white rounded-md pb-5 text-center shadow-md">
              <img
                src={Receive}
                alt=""
                className="w-80 lg:max-w-[230px] xl:max-w-[300px]   "
              />
              <p className="py-5 lg:text-xs">Receive payments</p>
            </div>
          </div>
        </div>

        {/* create store */}
        <div className=" flex flex-col max-w-7xl mx-auto ">
          {/* */}
          <div className="flex  justify-between max-w-2xl lg:max-w-4xl xl:max-w-5xl mx-auto p-5 mt-5 gap-10 ">
            <div className="hidden lg:block lg:w-5/12">
              <img src={ShopImage} alt="" className="shadow-md rounded-md" />
            </div>
            <div className="lg:w-6/12">
              <div className="">
                <h1 className="text-[#450089]  text-[18px] md:text-[26px] lg:text-[31px] xl:text-3xl  py-2">
                  Creating own online store has never been{" "}
                  <span className="text-[#D53F8C]"> easier</span>{" "}
                </h1>
              </div>
              <div className="grid md:grid-cols-2 gap-5  py-5">
                <div className="flex flex-col   justify-start gap-2 bg-white p-5 rounded-md shadow-sm">
                  <div className="flex items-center gap-2 justify-start">
                    <img src={verifyIcon} alt="" className="w-5 h-5" />
                    <p className="text-[#D53F8C]">It’s simple</p>
                  </div>
                  <p className="text-[#450089] text-xs lg:w-40">
                    Create your ecommerce shop on Zaplyn in 10 minutes -- as
                    easy as making an instagram post.
                  </p>
                </div>
                <div className="flex flex-col  justify-start gap-2 bg-white p-5 rounded-md shadow-sm">
                  <div className="flex items-center gap-2 justify-start">
                    <img src={lightningIcon} alt="" className="w-5 h-5" />
                    <p className="text-[#D53F8C]">It’s fast</p>
                  </div>
                  <p className="text-[#450089] text-xs lg:w-40">
                    Sell online without any need for expensive websites or high
                    fees.
                  </p>
                </div>
                <div className="flex flex-col   justify-start gap-2 bg-white p-5 rounded-md shadow-sm">
                  <div className="flex items-center gap-2 justify-start">
                    <img src={percentageICon} alt="" className="w-5 h-5" />
                    <p className="text-[#D53F8C]">It’s flexible</p>
                  </div>
                  <p className="text-[#450089] text-xs lg:w-40">
                    Add unlimited products to your shop, customise shop with a
                    range of themes and colours.
                  </p>
                </div>
                <div className="flex flex-col   justify-start gap-2 bg-white p-5 rounded-md shadow-sm">
                  <div className="flex items-center gap-2 justify-start">
                    <img src={categoryIcon} alt="" className="w-5 h-5" />
                    <p className="text-[#D53F8C]">It’s affordable</p>
                  </div>
                  <p className="text-[#450089] text-xs lg:w-40">
                    Only 5.99 a month for your shop, small fees for online
                    payments. Simply the cheapest option in the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* pricing */}

      <section ref={priRef}>
        <div className="bg-landingPlanCard rounded-md shadow-sm flex flex-col md:max-w-2xl lg:max-w-3xl xl:max-w-[920px] mx-2 md:mx-auto items-center p-10">
          <h3 className="text-[#450089] font-medium text-[24px] md:text-[26px] lg:text-[31px] xl:text-3xl ">
            Choose your plan
          </h3>

          <div className="flex flex-col md:flex-row md:items-center md:justify-between p-5  mt-5 md:mt-10  gap-10 max-w-5xl mx-auto text-[#450089]">
            <div className="flex flex-col bg-white rounded-md   text-center shadow-md h-[350px] w-60 xl:w-72 justify-between p-5  ">
              <div>
                <p className="">Starter Plan</p>
                <p className="text-4xl pt-3 text-[#D53F8C]">Free</p>
              </div>
              <div className="flex flex-col items-start  h-full mt-8 space-y-3 text-xs font-semibold">
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p>Up to 5 products </p>
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p>Unlimited orders</p>
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p> Zaplyn fees: 2.5% + 20p </p>
                </div>
              </div>
              <div>
                <Link to="/login">
                  <button className="text-xs w-full px-4 py-1  lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white ">
                    select
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col bg-white rounded-md  text-center shadow-md h-[350px] w-60 xl:w-72 justify-between p-5 ">
              <div>
                <p>Premium Plan</p>
                <p className="text-4xl pt-3 text-[#D53F8C]">
                  £5.99<span className="text-xs ">/month</span>{" "}
                </p>
              </div>
              <div className="flex flex-col items-start  h-full mt-8 space-y-3 text-xs font-semibold">
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p>Everything in free</p>{" "}
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p>Unlimited products</p>
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p> Zaplyn fees: 2.5% + 25p</p>
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p>Unlimited shops</p>
                </div>
                <div className="flex gap-2 items-center">
                  {" "}
                  <img src={tickIcon} alt="" className="w-5 h-5  " />
                  <p> Customer support 24/7</p>
                </div>
              </div>

              <div>
                <Link to="/login">
                  <button className="text-xs w-full px-4 py-1 lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white ">
                    select
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ section */}
      {/* <section
        ref={FAQsRef}
        className="mt-10 md:mt-16 text-[#450089] font-lexand "
      >
        <div className="flex flex-col max-w-7xl mx-auto items-center ">
          <div className="flex flex-col  w-full px-5 gap-3  md:max-w-xl lg:max-w-3xl xl:max-w-3xl  ">
            <h2 className="text-2xl md:text-3xl xl:text-4xl   text-left  font-medium pb-5 md:pb-2 ">
              FAQs
            </h2>
            {FAQdata.map((item, index) => (
              <div
                key={index}
                className="border-b-2 border-[#DAF6FF] pb-5 text-xs md:text-sm"
              >
                <div className="flex items-center justify-between">
                  <p className="font-semibold">{item.q}</p>
                  <button
                    onClick={() => handleFAQindexchange(index)}
                    className="w-5 h-5"
                  >
                    {isFaqOpen && faqIndex === index ? (
                      <img src={ArrowUp} alt="" className="" />
                    ) : (
                      <img src={ArrowDown} alt="" className="" />
                    )}
                  </button>
                </div>
                <p
                  className={` overflow-hidden transition-[max-height] duration-500 ease-in pt-3 ${
                    isFaqOpen && faqIndex === index ? "max-h-40" : "max-h-0"
                  }`}
                >
                  {item.a}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Bottom card */}

      <section className="flex flex-col max-w-7xl mx-auto items-center">
        <div className=" bg-landingBottomCard my-10 rounded-md shadow-sm flex flex-col-reverse md:flex-row mx-5 md:max-w-2xl lg:max-w-4xl md:mx-auto">
          <div className="flex flex-col items-center justify-center md:w-4/6 p-5">
            <h2 className="text-2xl md:text-3xl xl:text-4xl   text-left  font-medium  py-3">
              Start selling online today
            </h2>
            <div className=" mt-3">
              <Link to="/login">
                <button className="text-lg px-4 py-1 md:py-2 lg:px-5 xl:px-8 xl:text-base rounded-full bg-landingBtn text-white ">
                  Get started
                </button>
              </Link>
            </div>
          </div>
          <div className="md:w-2/6">
            <img
              src={BottomCardImage}
              alt=""
              className="rounded-t-md md:rounded-none"
            />
          </div>
        </div>
      </section>

      {/* footer */}

      <footer className="bg-landingFooter font-lexand">
        {/* mobile */}
        <div className="md:hidden flex flex-col items-center text-[#000F99] ">
          <div className="">
            <img src={ZaplynLogo} alt="" className="w-40 xl:w-60 " />
          </div>
          {/* <p className="text-sm font-semibold">Terms & Conditions</p> */}
          <button
            onClick={() => setIsPolicyModalOpen(true)}
            className="text-sm text-center"
          >
            Privacy Policy
          </button>
          <p className="text-xs pt-2 pb-2 text-[#3362BA]">
            2023 @ All Rights Reserved by General Enterprises
          </p>
        </div>

        {/* md+ devices  */}
        <div className="hidden  md:flex flex-col items-center text-[#000F99]  md:max-w-3xl lg:max-w-5xl  mx-auto">
          <div className="flex items-center justify-around  w-full">
            <div className="py-5">
              <img src={ZaplynLogo} alt="" className="w-40 xl:w-60 " />
            </div>
            <button
              onClick={() => handleClick(aboutRef)}
              className="text-sm font-semibold"
            >
              About
            </button>
            <button
              onClick={() => handleClick(priRef)}
              className="text-sm font-semibold"
            >
              Pricing
            </button>
            {/* <button
              onClick={() => handleClick(FAQsRef)}
              className="text-sm font-semibold"
            >
              FAQs
            </button> */}
            {/* <p className="text-sm font-semibold">Terms & Conditions</p> */}
            <button
              onClick={() => setIsPolicyModalOpen(true)}
              className="text-sm font-semibold"
            >
              Privacy Policy
            </button>
          </div>
          <div>
            <p className="text-xs  pb-2 text-[#3362BA]">
              2023 @ All Rights Reserved by General Enterprises{" "}
            </p>
          </div>
        </div>
      </footer>

      {/* policy Modal */}
      <Modal
        isOpen={isPolicyModalOpen}
        className=" max-w-xl z-50  p-2  mx-5 sm:mx-auto mt-10 h-5/6 rounded-lg  shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsPolicyModalOpen(false)}
      >
        <button
          onClick={() => setIsPolicyModalOpen(false)}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-[#450089] text-[#000F99] rounded-md text-sm  flex-shrink"
        >
          x
        </button>
        <div className="bg-white  py-2 px-5 flex flex-col items-start font-lexand overflow-y-scroll h-full rounded-md text-xs md:text-sm ">
          <h1 className="font-semibold pt-5 pb-2 text-xl">Privacy Policy</h1>
          <p className="text-justify py-1">Last updated: June 26, 2023</p>
          <p className="text-justify py-1">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="text-justify py-1">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
            <a
              href="https://www.privacypolicies.com/privacy-policy-generator/"
              target="_blank"
            >
              Privacy Policy Generator
            </a>
            .
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">
            Interpretation and Definitions
          </h1>
          <h2 className="font-semibold text-sm ">Interpretation</h2>
          <p className="text-justify py-1">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="font-semibold text-sm ">Definitions</h2>
          <p className="text-justify py-1">
            For the purposes of this Privacy Policy:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Application</strong> refers to Zaplyn, the software
                program provided by the Company.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to General Enterprises , 65 Pendant
                Court, E16 2TG, London, UK.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Country</strong> refers to: United Kingdom
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Service</strong> refers to the Application or the
                Website or both.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Website</strong> refers to zaplyn, accessible from{" "}
                <a
                  href="www.zaplyn.link"
                  rel="external nofollow noopener"
                  target="_blank"
                  className="text-blue-700"
                >
                  www.zaplyn.link
                </a>
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Collecting and Using Your Personal Data
          </h1>
          <h2 className="font-semibold text-sm ">Types of Data Collected</h2>
          <h3 className="font-semibold">Personal Data</h3>
          <p className="text-justify py-1">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify">Email address</p>
            </li>
            <li>
              <p className="text-justify">First name and last name</p>
            </li>
            <li>
              <p className="text-justify">Usage Data</p>
            </li>
          </ul>
          <h3 className="font-semibold">Usage Data</h3>
          <p className="text-justify py-1">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="text-justify py-1">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="text-justify py-1">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="text-justify py-1">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3 className="font-semibold">
            Information Collected while Using the Application
          </h3>
          <p className="text-justify py-1">
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <ul className="list-disc p-5">
            <li>Information regarding your location</li>
          </ul>
          <p className="text-justify py-1">
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </p>
          <p className="text-justify py-1">
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
          <h3 className="font-semibold">Tracking Technologies and Cookies</h3>
          <p className="text-justify py-1">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on Your Device. You can instruct Your browser to
              refuse all Cookies or to indicate when a Cookie is being sent.
              However, if You do not accept Cookies, You may not be able to use
              some parts of our Service. Unless you have adjusted Your browser
              setting so that it will refuse Cookies, our Service may use
              Cookies.
            </li>
            <li className="text-justify py-1">
              <strong>Web Beacons.</strong> Certain sections of our Service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </li>
          </ul>
          <p className="text-justify py-1">
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. Learn more about cookies on
            the{" "}
            <a
              href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking"
              target="_blank"
            >
              Privacy Policies website
            </a>{" "}
            article.
          </p>
          <p className="text-justify py-1">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>Necessary / Essential Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Session Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li>
              <p className="text-left py-1">
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Persistent Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>Functionality Cookies</strong>
              </p>
              <p className="text-justify py-1">Type: Persistent Cookies</p>
              <p className="text-justify py-1">Administered by: Us</p>
              <p className="text-justify py-1">
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <p className="text-justify py-1">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h2 className="font-semibold text-sm ">Use of Your Personal Data</h2>
          <p className="text-justify py-1">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="list-disc p-5">
            <li>
              <p className="text-justify py-1">
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For business transfers:</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </p>
            </li>
            <li>
              <p className="text-justify py-1">
                <strong>For other purposes</strong>: We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </p>
            </li>
          </ul>
          <p className="text-justify py-1">
            We may share Your personal information in the following situations:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </li>
            <li className="text-justify py-1">
              <strong>For business transfers:</strong> We may share or transfer
              Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or
              acquisition of all or a portion of Our business to another
              company.
            </li>
            <li className="text-justify py-1">
              <strong>With Affiliates:</strong> We may share Your information
              with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners
              or other companies that We control or that are under common
              control with Us.
            </li>
            <li className="text-justify py-1">
              <strong>With business partners:</strong> We may share Your
              information with Our business partners to offer You certain
              products, services or promotions.
            </li>
            <li className="text-justify py-1">
              <strong>With other users:</strong> when You share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside.
            </li>
            <li className="text-justify py-1">
              <strong>With Your consent</strong>: We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h2 className="font-semibold text-sm ">
            Retention of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="text-justify py-1">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2 className="font-semibold text-sm ">
            Transfer of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="text-justify py-1">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="text-justify py-1">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h2 className="font-semibold text-sm ">Delete Your Personal Data</h2>
          <p className="text-justify py-1">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="text-justify py-1">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p className="text-justify py-1">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="text-justify py-1">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h2 className="font-semibold text-sm ">
            Disclosure of Your Personal Data
          </h2>
          <h3 className="font-semibold">Business Transactions</h3>
          <p className="text-justify py-1">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3 className="font-semibold">Law enforcement</h3>
          <p className="text-justify py-1">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3 className="font-semibold">Other legal requirements</h3>
          <p className="text-justify py-1">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul className="list-disc p-5">
            <li className="text-justify py-1">
              Comply with a legal obligation
            </li>
            <li className="text-justify py-1">
              Protect and defend the rights or property of the Company
            </li>
            <li className="text-justify py-1">
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li className="text-justify py-1">
              Protect the personal safety of Users of the Service or the public
            </li>
            <li className="text-justify py-1">
              Protect against legal liability
            </li>
          </ul>
          <h2 className="font-semibold text-sm ">
            Security of Your Personal Data
          </h2>
          <p className="text-justify py-1">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Children's Privacy
          </h1>
          <p className="text-justify py-1">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="text-justify py-1">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h1 className="font-semibold pt-5 pb-2  text-xl">
            Links to Other Websites
          </h1>
          <p className="text-justify py-1">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="text-justify py-1">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">
            Changes to this Privacy Policy
          </h1>
          <p className="text-justify py-1">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="text-justify py-1">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p className="text-justify py-1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1 className="font-semibold pt-5 pb-2 text-xl">Contact Us</h1>
          <p className="text-justify py-1">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul className="list-disc p-5">
            <li className="text-left py-1">
              By email:{" "}
              <a
                href="/cdn-cgi/l/email-protection"
                class="__cf_email__"
                data-cfemail="4f2c20213b2e2c3b0f282a212a3d2e232a213b2a3d3f3d263c2a3c612c20613a24"
              >
                contact@generalenterprises.co.uk
              </a>
            </li>
          </ul>
          <script
            data-cfasync="false"
            src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
          ></script>
        </div>
      </Modal>
    </section>
  );
}

export default App;
