import React, { useContext, useEffect, useMemo, useState } from "react";
import { convertToBase64, createTileRef } from "../../../../helper/helper";
import imageCompression from "browser-image-compression";
import { GlobalContext } from "../../../GlobalContext";
import ReactSwitch from "react-switch";
import Select from "react-select";
import Modal from "react-modal";
import Endpoints from "../../../../api/endpoints";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import PostMessagePopup from "../../../PostMessagePopup";
import defaultThemeImg from "../../../../assets/defaultTheme.svg";
import darkThemeImg from "../../../../assets/darkTheme.svg";
import grayThemeImg from "../../../../assets/grayTheme.svg";
import darkblueThemeImg from "../../../../assets/darkBlueTheme.svg";
import greenThemeImg from "../../../../assets/greenTheme.svg";
import redThemeImg from "../../../../assets/redTheme.svg";
import "../../sharePage/Theme.css";
import { S3 } from "aws-sdk";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { CirclePicker } from "react-color";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import getToken from "../../../Token";

const s3 = new S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: "v4",
});

const themeData = [
  {
    theme: "default",
    themeBtn: "defaultBtn",
    themImg: defaultThemeImg,
  },
  {
    theme: "dark",
    themeBtn: "darkBtn",
    themImg: darkThemeImg,
  },
  {
    theme: "gray",
    themeBtn: "grayBtn",
    themImg: grayThemeImg,
  },
  {
    theme: "darkBlue",
    themeBtn: "darkBlueBtn",
    themImg: darkblueThemeImg,
  },
  {
    theme: "greenAndBlue",
    themeBtn: "greenAndBlueBtn",
    themImg: greenThemeImg,
  },
  {
    theme: "redAndPink",
    themeBtn: "redAndPinkBtn",
    themImg: redThemeImg,
  },
];

const fontData = [
  {
    font: "caveat",
  },
  {
    font: "lexand",
  },
  { font: "Inconsolata" },
  {
    font: "dancing",
  },
  {
    font: "dmsans",
  },
  {
    font: "dongle",
  },
  {
    font: "fredoka",
  },
  {
    font: "indie",
  },
  {
    font: "lato",
  },
  {
    font: "manjari",
  },
  {
    font: "merriweather",
  },
  {
    font: "mina",
  },
  {
    font: "miriam",
  },
  {
    font: "oswald",
  },
  {
    font: "ptserif",
  },
  {
    font: "raleway",
  },
];

function EditShopModal({ shopData, closeOnClick }) {
  const queryClient = useQueryClient();

  const { fetchedProductsList, availableProducts: products } =
    useContext(GlobalContext);

  // step
  const [currentStep, setCurrentStep] = useState(1);

  const [showTheme, setShowTheme] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  //create product modal
  const [createProductModalIsOpen, setCreateProductModalIsOpen] =
    useState(false);
  const setCreateProductModalIsOpenToFalse = () => {
    setCreateProductModalIsOpen(false);
  };

  // shop data
  const [shopName, setShopName] = useState("");
  const [shopNameError, setShopNameError] = useState("");
  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState("");

  const [shopOwnerEmail, setShopOwnerEmail] = useState("");
  const [shopOwnerEmailError, setShopOwnerEmailError] = useState("");

  const [shopOwnerContact, setShopOwnerContact] = useState("");
  const [shopOwnerContactError, setShopOwnerContactError] = useState("");

  const [shopOwnerAddress, setShopOwnerAddress] = useState("");

  const [shopImage, setShopImage] = useState("");
  const [viewImage, setViewImage] = useState("");

  const [chosenCurrency, setChosenCurrency] = useState("");
  const [shopShareLink, setShopShareLink] = useState(false);

  const [scheduleDelivery, setScheduleDelivery] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(false);

  const [shopDiscount, setShopDiscount] = useState(null);

  const [multipleBooking, setMultipleBooking] = useState(true);

  // payment percentage
  const [paymentPercentage, setPaymentPercentage] = useState(100);

  //
  //------------------ Booking Slots start ----------------
  //
  const [showBookingSlots, setShowBookingSlots] = useState(false);

  // slot items

  const [selectedBookingDay, setSelectedBookingDay] = useState("Sunday");

  // sunday
  const [isSundayMenuOpen, setIsSundayMenuOpen] = useState(false);
  const [SundaySlots, setSundaySlots] = useState([]);
  const [SundaySlotsCount, setSundaySlotsCount] = useState(1);
  const handleAddSundaySlot = () => {
    if (SundaySlotsCount < 10) {
      setSundaySlots([
        ...SundaySlots,
        {
          slotNo: SundaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setSundaySlotsCount(SundaySlotsCount + 1);
    }
  };

  // monday
  const [isMondayMenuOpen, setIsMondayMenuOpen] = useState(false);

  const [MondaySlots, setMondaySlots] = useState([]);
  const [MondaySlotsCount, setMondaySlotsCount] = useState(1);
  const handleAddMondaySlot = () => {
    if (MondaySlotsCount < 10) {
      setMondaySlots([
        ...MondaySlots,
        {
          slotNo: MondaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setMondaySlotsCount(MondaySlotsCount + 1);
    }
  };

  // tuesday

  const [isTuesdayMenuOpen, setIsTuesDayOpen] = useState(false);

  const [TuesdaySlots, setTuesdaySlots] = useState([]);
  const [TuesdaySlotsCount, setTuesdaySlotsCount] = useState(1);
  const handleAddTuesdaySlot = () => {
    if (TuesdaySlotsCount < 10) {
      setTuesdaySlots([
        ...TuesdaySlots,
        {
          slotNo: TuesdaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setTuesdaySlotsCount(TuesdaySlotsCount + 1);
    }
  };

  // wednesday

  const [isWednesdayMenuOpen, setIsWednesdayMenuOpen] = useState(false);

  const [WednesdaySlots, setWednesdaySlots] = useState([]);
  const [WednesdaySlotsCount, setWednesdaySlotsCount] = useState(1);
  const handleAddWednesdaySlot = () => {
    if (WednesdaySlotsCount < 10) {
      setWednesdaySlots([
        ...WednesdaySlots,
        {
          slotNo: WednesdaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setWednesdaySlotsCount(WednesdaySlotsCount + 1);
    }
  };

  // Thursday

  const [isThursdayMenuOpen, setIsThursdayMenuOpen] = useState(false);

  const [ThursdaySlots, setThursdaySlots] = useState([]);
  const [ThursdaySlotsCount, setThursdaySlotsCount] = useState(1);
  const handleAddThursdaySlot = () => {
    if (ThursdaySlotsCount < 10) {
      setThursdaySlots([
        ...ThursdaySlots,
        {
          slotNo: ThursdaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setThursdaySlotsCount(ThursdaySlotsCount + 1);
    }
  };

  // friday

  const [isFridayMenuOpen, setIsFridayMenuOpen] = useState(false);

  const [FridaySlots, setFridaySlots] = useState([]);
  const [FridaySlotsCount, setFridaySlotsCount] = useState(1);
  const handleAddFridaySlot = () => {
    if (FridaySlotsCount < 10) {
      setFridaySlots([
        ...FridaySlots,
        {
          slotNo: FridaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setFridaySlotsCount(FridaySlotsCount + 1);
    }
  };

  // saturday
  const [isSaturdayMenuOpen, setIsSaturdayMenuOpen] = useState(false);

  const [SaturdaySlots, setSaturdaySlots] = useState([]);
  const [SaturdaySlotsCount, setSaturdaySlotsCount] = useState(1);
  const handleAddSaturdaySlot = () => {
    if (SaturdaySlotsCount < 10) {
      setSaturdaySlots([
        ...SaturdaySlots,
        {
          slotNo: SaturdaySlotsCount + 1,
          from: dayjs(Date.now()),
          to: dayjs(Date.now()),
          isLocked: false,
        },
      ]);
      setSaturdaySlotsCount(SaturdaySlotsCount + 1);
    }
  };

  //
  //------------------ Booking Slots End ----------------
  //

  // theme
  const [selectedTheme, setSelectedTheme] = useState("redAndPink");
  const [selectedThemeBtn, setSelectedThemeBtn] = useState("redAndPinkBtn");
  const [selectedThemeBtnStyle, setSelectedThemeBtnStyle] = useState("");
  const [selectedThemeTileBorder, setSelectedThemeTileBorder] =
    useState("none");
  const [selectedThemeTileBorderStyle, setSelectedThemeTileBorderStyle] =
    useState("rounded-lg");
  const [selectedThemeFont, setselectedThemeFont] = useState("lexand");
  const [selectedThemeView, setSelectedThemeView] = useState("list");

  const handleTileView = (event) => {
    setSelectedThemeView(event.target.value);
  };

  const handleBorderTile = (event) => {
    setSelectedThemeTileBorder(event.target.value);
  };

  const handleColorSelection = (color) => {
    if (color.hex === "#9e9e9e") {
      setSelectedTheme("gray");
      setSelectedThemeBtn("grayBtn");
    }
    if (color.hex === "#e53935") {
      setSelectedTheme("red");
      setSelectedThemeBtn("redBtn");
    }
    if (color.hex === "#ffeb3b") {
      setSelectedTheme("yellow");
      setSelectedThemeBtn("yellowBtn");
    }
    if (color.hex === "#4caf50") {
      setSelectedTheme("green");
      setSelectedThemeBtn("greenBtn");
    }
    if (color.hex === "#2196f3") {
      setSelectedTheme("blue");
      setSelectedThemeBtn("blueBtn");
    }
    if (color.hex === "#3f51b5") {
      setSelectedTheme("indigo");
      setSelectedThemeBtn("indigoBtn");
    }
    if (color.hex === "#9c27b0") {
      setSelectedTheme("purple");
      setSelectedThemeBtn("purpleBtn");
    }
    if (color.hex === "#e91e63") {
      setSelectedTheme("pink");
      setSelectedThemeBtn("pinkBtn");
    }
    if (color.hex === "#673ab7") {
      setSelectedTheme("deep-purple");
      setSelectedThemeBtn("deep-purpleBtn");
    }
    if (color.hex === "#03a9f4") {
      setSelectedTheme("light-blue");
      setSelectedThemeBtn("light-blueBtn");
    }
    if (color.hex === "#009688") {
      setSelectedTheme("teal");
      setSelectedThemeBtn("tealBtn");
    }
    if (color.hex === "#cddc39") {
      setSelectedTheme("lime");
      setSelectedThemeBtn("limeBtn");
    }
    if (color.hex === "#ffc107") {
      setSelectedTheme("amber");
      setSelectedThemeBtn("amberBtn");
    }
    if (color.hex === "#ff5722") {
      setSelectedTheme("deep-orange");
      setSelectedThemeBtn("deep-orangeBtn");
    }
    if (color.hex === "#795548") {
      setSelectedTheme("brown");
      setSelectedThemeBtn("brownBtn");
    }
  };

  //fetched || selected products
  //   categories
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const handleCategoryOptionChange = (e) => {
    const option = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedCategoryOptions([...selectedCategoryOptions, option]);
    } else {
      setSelectedCategoryOptions(
        selectedCategoryOptions.filter((item) => item !== option)
      );
    }
  };

  const [availableProducts, setAvailableProducts] = useState(null);

  const handleMoveToStep2 = () => {
    setCurrentStep((prevstep) => prevstep + 1);
  };
  const handleBackToStep1 = () => {
    setCurrentStep((prevstep) => prevstep - 1);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSelect = (tile) => {
    setSelectedProducts((prevSelectedTiles) => [...prevSelectedTiles, tile]);
  };

  const handleRemove = (tile) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((item) => item !== tile)
    );
  };

  useEffect(() => {
    const selectOptions = fetchedProductsList?.map((option) => ({
      value: option.ZaplynTileRef,
      label: option.itemname,
      category: option.category,
      image: option?.images.shift(),
    }));
    setAvailableProducts(selectOptions);
  }, [fetchedProductsList]);

  useEffect(() => {
    if (availableProducts && selectedCategoryOptions) {
      const filteredData = availableProducts?.filter((item) => {
        let categoryFilter = true;
        if (selectedCategoryOptions?.length > 0) {
          categoryFilter = selectedCategoryOptions
            .map((option) => option.toLowerCase())
            .includes(item.category.toLowerCase());
        }

        return categoryFilter;
      });

      setSelectedProducts(
        filteredData
          .filter((item) => selectedProducts.includes(item.value))
          .map((item) => item.value)
      );
      // console.log("newPRods", filteredData.filter());
    }
  }, [selectedCategoryOptions]);

  const handleImageUpload = async (e) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1280,
    };
    const compressedFile = await imageCompression(e.target.files[0], options);
    const base64 = await convertToBase64(compressedFile);

    setShopImage(base64);
    setViewImage(e.target.files[0]);
  };

  const handleEditShop = async (e) => {
    e.preventDefault();

    try {
      const fileName = `${Date.now()}-${viewImage.name}`;

      let imgLocation;
      if (viewImage) {
        const accesstoken = getToken();
        const imageRes = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}zaplyn-image-upload/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accesstoken,
            },
            body: JSON.stringify({
              image: shopImage,
              filename: fileName,
            }),
          }
        );
        imgLocation = await imageRes.json();
      }

      const response = await Endpoints.EditShop({
        shopRef: shopData.ZaplynShopRef,
        currency: chosenCurrency,
        shopname: shopName,
        description: desc,
        linkEnabled: shopShareLink,
        partial: Number(paymentPercentage),
        multiple_bookings: multipleBooking,
        email: shopOwnerEmail,
        mobile: shopOwnerContact,
        address: shopOwnerAddress,
        booking_time: {
          Sunday: SundaySlots,
          Monday: MondaySlots,
          Tuesday: TuesdaySlots,
          Wednesday: WednesdaySlots,
          Thursday: ThursdaySlots,
          Friday: FridaySlots,
          Saturday: SaturdaySlots,
        },
        enable_scheduling: scheduleDelivery,
        shipping_enabled: shippingInfo,
        discount: shopDiscount || 0,
        img_file: imgLocation?.url || shopImage || "",
        theme: `${selectedTheme},${selectedThemeBtn},${selectedThemeBtnStyle},${selectedThemeTileBorder},${selectedThemeTileBorderStyle},${selectedThemeFont},null,${selectedThemeView}`,
        productlist: selectedProducts.toString() || "",
        categorylist: selectedCategoryOptions.toString() || "",
      });

      if (response) {
        toast(response["Notification-text"]);
        await queryClient.invalidateQueries("shopsData");
        setIsLoadingModalOpen(false);
        closeOnClick();
      }
    } catch (error) {
      console.log(error);
      setIsLoadingModalOpen(false);
    }
  };

  useEffect(() => {
    const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
    fetch(link + "share-shop/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ZaplynShopRef: shopData.ZaplynShopRef,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        // toast(JSON.stringify( json["Notification-text"]).replace(/"|'/g, ""));
        setShopImage(json.image);
        setShopName(json.shopname);
        setDesc(json.description);
        setShopShareLink(json.link_enabled);
        setChosenCurrency(json.currency);
        setShopDiscount(json.discount);
        setScheduleDelivery(json?.enable_scheduling);
        setShippingInfo(json?.shipping_enabled);
        setSelectedProducts(json.ZaplynTileRef.split(","));
        setSelectedCategoryOptions(json.category.split(","));
        setSelectedTheme(json.theme?.theme);
        setSelectedThemeBtn(json.theme?.themeBtn);
        setPaymentPercentage(json.partial);
        setShopOwnerContact(json?.mobile);
        setShopOwnerEmail(json?.email);
        setShopOwnerAddress(json?.address);
        setSelectedThemeBtnStyle(json?.theme?.themeBtnStyle);

        setSelectedThemeTileBorder(json.theme?.showBorder);
        setSelectedThemeView(json.theme?.borderType);

        setMultipleBooking(json?.multiple_bookings);
        if (
          json?.booking_time !== null &&
          Object.keys(json?.booking_time).length > 0
        ) {
          setSundaySlots(json.booking_time.Sunday);
          setMondaySlots(json.booking_time.Monday);
          setTuesdaySlots(json.booking_time.Tuesday);
          setWednesdaySlots(json.booking_time.Wednesday);
          setThursdaySlots(json.booking_time.Thursday);
          setFridaySlots(json.booking_time.Friday);
          setSaturdaySlots(json.booking_time.Saturday);

          setSundaySlotsCount(json.booking_time?.Sunday?.length);
          setMondaySlotsCount(json.booking_time?.Monday?.length);
          setTuesdaySlotsCount(json.booking_time?.Tuesday?.length);
          setWednesdaySlotsCount(json.booking_time?.Wednesday?.length);
          setThursdaySlotsCount(json.booking_time?.Thursday?.length);
          setFridaySlotsCount(json.booking_time?.Friday?.length);
          setSaturdaySlotsCount(json.booking_time?.Saturday?.length);
        }

        setSelectedThemeTileBorderStyle(json.theme?.tileStyle);
        setselectedThemeFont(json.theme?.font);
      });
  }, []);

  const categories = useMemo(() => {
    return fetchedProductsList?.map((item) => item.category);
  }, [fetchedProductsList]);

  const uniqueCategories = useMemo(() => {
    return [...new Set(categories)];
  }, [categories]);

  return (
    <div className="max-w-2xl p-4 mx-auto bg-gray-50  rounded-md shadow-md font-lexand">
      {/* shop details section */}
      {!showBookingSlots && !showTheme && currentStep === 1 && (
        <>
          <h1 className="text-xl font-bold  capitalize ">Edit Shop</h1>
          <form onSubmit={handleEditShop}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-6">
              {shopImage ? (
                <div className=" row-start-2 sm:row-start-1 sm:col-span-2">
                  <label className=" text-sm flex font-medium text-black">
                    Shop Logo
                  </label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                      <img src={shopImage} alt="" className="w-full h-full" />
                    </div>
                  </div>
                  <label
                    htmlFor="file-edit"
                    className="relative cursor-pointer "
                  >
                    <div className=" text-xs text-white mt-1">
                      <span className="rounded-md px-2 py-0.5 font-medium bg-gradient-to-b from-purple-200 to-pink-300 focus:outline-none">
                        Edit logo
                      </span>
                    </div>
                  </label>
                  <input
                    id="file-edit"
                    name="file-edit"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              ) : (
                <div className=" row-start-2 sm:row-start-1 sm:col-span-2">
                  <label className=" text-sm flex font-medium text-black">
                    Shop Logo
                  </label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer "
                      >
                        <svg
                          className="mx-auto h-12 w-12 text-black"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                        </svg>
                        <div className=" text-xs text-gray-600">
                          <span className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            Upload a file
                          </span>
                          <p className="pl-1 text-black">or drag and drop</p>
                          <p className="text-xs text-black">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </label>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="sm:col-span-4">
                <div>
                  <label className="text-black flex " htmlFor="shopname">
                    Shop Name
                  </label>
                  <input
                    id="shopname"
                    className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full ${
                      shopNameError
                        ? "text-right placeholder-red-700 text-xs"
                        : "text-left text-sm"
                    } `}
                    type="Text"
                    required
                    value={shopName}
                    onClick={() => setShopNameError("")}
                    onChange={(e) => setShopName(e.target.value)}
                    placeholder={
                      shopNameError ? `${shopNameError}` : "Add Shop Name*"
                    }
                    rows="1"
                    maxh={50}
                  />
                </div>

                <div>
                  <label
                    className="text-black flex"
                    htmlFor="passwordConfirmation"
                  >
                    Description
                  </label>
                  <textarea
                    id="textarea"
                    type="textarea"
                    className={`focus:outline-none p-2 shadow-md border border-pink-300 rounded-md w-full h-24 resize-none ${
                      descError
                        ? "text-right placeholder-red-700 text-xs"
                        : "text-left text-sm"
                    } `}
                    value={desc}
                    onClick={() => setDescError("")}
                    onChange={(e) => setDesc(e.target.value)}
                    required
                    placeholder={
                      descError ? `${descError}` : "Add Description*"
                    }
                    rows="1"
                    maxh={150}
                  ></textarea>
                </div>
              </div>
            </div>

            <hr class="mt-6 mb-1 border-b-1 border-gray-200" />

            <div className=" grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <label className="text-black flex " htmlFor="shopowneremail">
                  Email
                </label>
                <input
                  id="shopowneremail"
                  className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full ${
                    shopOwnerEmailError
                      ? "text-right placeholder-red-700 text-xs"
                      : "text-left text-sm"
                  } `}
                  type="Text"
                  required
                  value={shopOwnerEmail}
                  onClick={() => setShopOwnerEmailError("")}
                  onChange={(e) => setShopOwnerEmail(e.target.value)}
                  placeholder={
                    shopOwnerEmailError
                      ? `${shopOwnerEmailError}`
                      : "Add Email*"
                  }
                  rows="1"
                  maxLength={50}
                />
              </div>
              <div className="sm:col-span-1">
                <label className="text-black flex " htmlFor="showownercontact">
                  Contact
                </label>
                <input
                  id="showownercontact"
                  className={`focus:outline-none p-2  shadow-md border placehol border-pink-300 rounded-md h-10  w-full ${
                    shopOwnerContactError
                      ? "text-right placeholder-red-700 text-xs"
                      : "text-left text-sm"
                  } `}
                  type="Number"
                  required
                  value={shopOwnerContact}
                  onClick={() => setShopOwnerContactError("")}
                  onChange={(e) => setShopOwnerContact(e.target.value)}
                  placeholder={
                    shopOwnerContactError
                      ? `${shopOwnerContactError}`
                      : "Add Contact*"
                  }
                  rows="1"
                  maxLength={50}
                />
              </div>
              <div className="sm:col-span-2">
                <label className="text-black flex " htmlFor="shopowneraddresss">
                  Address
                </label>
                <textarea
                  id="shopowneraddresss"
                  type="textarea"
                  className={`focus:outline-none p-2 shadow-md border border-pink-300 rounded-md w-full h-16 resize-none text-left text-sm `}
                  value={shopOwnerAddress}
                  onChange={(e) => setShopOwnerAddress(e.target.value)}
                  placeholder="Address"
                  rows="1"
                  maxLength={150}
                ></textarea>
              </div>
            </div>
            <hr class="mt-4 mb-1 border-b-1 border-gray-200" />
            <div className="flex flex-wrap items-center justify-between mt-2">
              <div className="flex items-end text-xs md:text-sm gap-2  ">
                {/* <p>Default Currency</p>
                <select
                  className="focus:outline-none w-20 tify-end  shadow-md bg-white border border-pink-300 rounded-md p-1 cursor-pointer"
                  required
                  onChange={(e) => setChosenCurrency(e.target.value)}
                >
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select> */}
                <p className="text-xs">
                  {chosenCurrency} {`(£)`}
                </p>
              </div>

              {/* <div className="flex items-center text-xs md:text-sm gap-2">
                <p>VAT </p>
                <input
                  id="vat"
                  className="focus:outline-none p-1 h-8 text-gray-600 w-8 bg-transparent "
                  value="10"
                  readOnly
                  required
                  rows="1"
                />
              </div> */}

              <div className="flex gap-2 items-end">
                <label
                  className="text-black flex  text-xs md:text-sm"
                  htmlFor="shopDiscount"
                >
                  Shop Discount {`(%)`}{" "}
                  <Tooltip
                    enterTouchDelay={0}
                    TransitionComponent={Zoom}
                    placement="top"
                    arrow
                    leaveTouchDelay={5000}
                    title="Set an amount from 0 to 100 if you want to offer a discount on the total order amount. Default amount is 0."
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                        fill="rgba(235,117,253,1)"
                      ></path>
                    </svg>
                  </Tooltip>
                </label>
                <input
                  id="shopDiscount"
                  className="focus:outline-none p-1  shadow-md border text-xs border-pink-300 rounded-md h-8  w-24 md:w-40 text-left"
                  type="Number"
                  required
                  value={shopDiscount}
                  onChange={(e) => setShopDiscount(e.target.value)}
                  placeholder="Add Discount *"
                  rows="1"
                  maxh={5}
                />
              </div>

              <div className="text-sm py-2 px-2 border border-pink-300 shadow-md  rounded-md mt-2 flex items-center justify-start">
                <p className=" mr-2 my-1 font-semibold text-gray-600 text-xs md:text-sm">
                  Enable Link
                </p>
                <ReactSwitch
                  checked={shopShareLink}
                  onChange={() => setShopShareLink(!shopShareLink)}
                  height={20}
                  handleDiameter={12}
                  width={40}
                />
              </div>

              <button
                onClick={() => setShowTheme(true)}
                className="py-1 px-4 bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text rounded-lg  font-bold text-sm md:text-lg animate-pulse shadow-md"
              >
                Themes
              </button>
            </div>

            <div className="flex flex-wrap mt-2 items-center justify-between">
              <div className="text-sm py-2  mt-2  rounded-md  flex flex-col gap-1 justify-start">
                <div className="flex gap-2">
                  <p className="  flex text-gray-600 text-xs md:text-sm">
                    Require Appointment Booking
                    <Tooltip
                      enterTouchDelay={0}
                      TransitionComponent={Zoom}
                      placement="top"
                      leaveTouchDelay={5000}
                      arrow
                      title="Enabling this feature allows your customers to book an appointment at designated time slots that you save here."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                          fill="rgba(235,117,253,1)"
                        ></path>
                      </svg>
                    </Tooltip>
                  </p>
                  <ReactSwitch
                    checked={scheduleDelivery}
                    onChange={() => setScheduleDelivery(!scheduleDelivery)}
                    handleDiameter={12}
                    height={20}
                    width={40}
                  />
                </div>
                <div
                  className={`flex flex-wrap items-center justify-between ${
                    !scheduleDelivery ? "opacity-60" : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      if (scheduleDelivery) {
                        setShowBookingSlots(true);
                      } else {
                        console.log("");
                      }
                    }}
                    className=" ml-1 px-2 border border-pink-300 shadow-md  rounded-md flex items-center justify-start cursor-pointer"
                  >
                    <p className=" mr-2 font-semibold text-gray-600 text-xs md:text-sm">
                      Add Time Slot
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-5 h-5"
                    >
                      <path d="M10.0859 12.0001L5.29297 16.793L6.70718 18.2072L12.9143 12.0001L6.70718 5.79297L5.29297 7.20718L10.0859 12.0001ZM17.0001 6.00008L17.0001 18.0001H15.0001L15.0001 6.00008L17.0001 6.00008Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="text-sm py-2 mt-2 px-2 border border-pink-300 shadow-md  rounded-md  flex items-center justify-start">
                <p className=" mr-2 my-1 flex  text-gray-600 text-xs md:text-sm">
                  Ask for Shipping Information?
                  <Tooltip
                    enterTouchDelay={0}
                    TransitionComponent={Zoom}
                    placement="top"
                    leaveTouchDelay={5000}
                    arrow
                    title="Enabling this feature allows you to collect shipping address from customers. Use it only if necessary, for example if you are planning to ship physical goods."
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                        fill="rgba(235,117,253,1)"
                      ></path>
                    </svg>
                  </Tooltip>
                </p>
                <ReactSwitch
                  checked={shippingInfo}
                  onChange={() => setShippingInfo(!shippingInfo)}
                  handleDiameter={12}
                  height={20}
                  width={40}
                />
              </div>

              <div className="flex items-end text-xs md:text-sm gap-2 mt-1  ">
                <p className="flex">
                  Payment required for booking order
                  <Tooltip
                    enterTouchDelay={0}
                    TransitionComponent={Zoom}
                    placement="top"
                    leaveTouchDelay={5000}
                    arrow
                    title="Set the amount to be paid by customer in advance to book the order (from 0 to 100%)."
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"
                        fill="rgba(235,117,253,1)"
                      ></path>
                    </svg>
                  </Tooltip>
                </p>
                <select
                  className="focus:outline-none w-16 md:w-20 tify-end  shadow-md bg-white border border-pink-300 rounded-md p-1 cursor-pointer"
                  required
                  value={paymentPercentage}
                  onChange={(e) => setPaymentPercentage(e.target.value)}
                >
                  <option value={parseInt("100")}>100 %</option>
                  <option value={parseInt("50")}>50 %</option>
                  <option value={parseInt("25")}>25 %</option>
                  <option value={parseInt("0")}>0 %</option>
                </select>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-end mt-8 gap-4">
              <div className=" relative w-full">
                {uniqueCategories?.length > 0 ? (
                  <p
                    onClick={handleMoveToStep2}
                    className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
                  >
                    Products
                  </p>
                ) : (
                  <>
                    <p className=" absolute -top-4 right-4 text-xs text-gray-500">
                      You do not have any products to add!{" "}
                    </p>
                    <p
                      onClick={() => {
                        setCreateProductModalIsOpen(true);
                      }}
                      className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
                    >
                      Create Product
                    </p>
                  </>
                )}
              </div>
              <button
                type="submit"
                className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
              >
                Save
              </button>
            </div>
          </form>
        </>
      )}

      {/* products section */}
      {!showBookingSlots && !showTheme && currentStep === 2 && (
        <>
          <div
            className={`${uniqueCategories?.length > 0 ? "block" : "hidden"}`}
          >
            <h6 class="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Available Categories for you shop
            </h6>
            <div className="grid grid-cols-2 md:grid-cols-4">
              {uniqueCategories?.map((option) => (
                <label key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedCategoryOptions.includes(option)}
                    onChange={handleCategoryOptionChange}
                  />
                  <span className="ml-2">{option}</span>
                </label>
              ))}
            </div>
            <hr class="mt-6 border-b-1 border-gray-200" />
          </div>
          {selectedCategoryOptions?.length > 0 ? (
            selectedCategoryOptions?.map((category, index) => (
              <div className="p-1" key={index}>
                <h1 className="text-gray-700 text-left">{category}</h1>
                <div className="grid md:grid-cols-3 p-1">
                  {availableProducts
                    ?.filter((item) => item.category === category)
                    .map((product) => (
                      <div
                        className={`flex gap-2 p-1 m-1 cursor-pointer  ${
                          selectedProducts?.includes(product.value)
                            ? "bg-gray-100 opacity-30 border border-pink-300 rounded-lg"
                            : ""
                        }`}
                        key={product.value}
                        onClick={() =>
                          selectedProducts?.includes(product.value)
                            ? handleRemove(product.value)
                            : handleSelect(product.value)
                        }
                      >
                        <img
                          src={product.image}
                          alt=""
                          className="w-12 h-12 rounded-md"
                        />
                        <div className="text-xs text-left">
                          <p className="text-sm">{product.label}</p>
                          <p className="text-gray-500">{product.value}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className="flex flex-col md:flex-row justify-end mt-8 gap-4">
            <div className=" relative w-full">
              <p
                onClick={handleBackToStep1}
                className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
              >
                Back
              </p>
            </div>
            <button
              onClick={handleEditShop}
              className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
            >
              Save Changes
            </button>
          </div>
        </>
      )}

      {/* theme section */}
      {!showBookingSlots && showTheme && (
        <>
          <div className="">
            <h6 class="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Available Themes for you shop
            </h6>

            <div className="flex items-center justify-center max-w-md mx-auto ">
              <CirclePicker
                onChangeComplete={handleColorSelection}
                circleSpacing={20}
                colors={[
                  "#9e9e9e",
                  "#e53935",
                  "#ffeb3b",
                  "#4caf50",
                  "#2196f3",
                  "#3f51b5",
                  "#9c27b0",
                  "#e91e63",
                  "#673ab7",
                  "#03a9f4",
                  "#009688",
                  "#cddc39",
                  "#ffc107",
                  "#ff5722",
                  "#795548",
                ]}
              />
            </div>
            <hr class="mt-4 border-b-1 border-gray-200" />

            <p className=" text-gray-400 font-semibold ">BUTTON STYLES</p>
            <div className="flex mt-2 gap-4 items-center justify-around">
              <div>
                <button
                  onClick={() => setSelectedThemeBtnStyle("")}
                  className={` ${selectedThemeBtn} relative cursor-pointer px-4 text-sm   shadow-md md:px-10 py-1 md:p-1.5  `}
                >
                  Button
                  {selectedThemeBtnStyle === "" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#70ab77"
                      className="absolute top-0 right-0 flex-shrink-0 w-6 h-6 text-violet-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </button>
                <p className="text-xs pt-0.5 text-gray-600"> Default</p>
              </div>
              <div>
                <button
                  onClick={() => setSelectedThemeBtnStyle("rounded-xl")}
                  className={` ${selectedThemeBtn} relative cursor-pointer  px-4 text-sm  shadow-md md:px-10 py-1 md:p-1.5   rounded-lg`}
                >
                  Button
                  {selectedThemeBtnStyle === "rounded-xl" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#70ab77"
                      className="absolute top-0 right-0 flex-shrink-0 w-6 h-6 text-violet-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </button>
                <p className="text-xs pt-0.5 text-gray-600"> Rounded</p>
              </div>
              <div>
                <button
                  onClick={() => setSelectedThemeBtnStyle("rounded-full")}
                  className={` ${selectedThemeBtn} relative cursor-pointer px-4 text-sm  shadow-md md:px-10 py-1 md:p-1.5   rounded-full`}
                >
                  Button
                  {selectedThemeBtnStyle === "rounded-full" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#70ab77"
                      className="absolute top-0 right-0 flex-shrink-0 w-6 h-6 text-violet-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </button>
                <p className="text-xs pt-0.5 text-gray-600"> Rounded Full</p>
              </div>
            </div>
            <hr class="mt-4 border-b-1 border-gray-200" />

            <div className="space-y-2">
              <p className="text-gray-400 font-semibold ">TILE STYLES</p>
              {/* tileview */}
              <div className="flex gap-3 items-center">
                <p className=" my-2">Product Tile View</p>

                <div className="flex items-center gap-1 text-xs">
                  <input
                    type="radio"
                    id="list"
                    value="list"
                    checked={selectedThemeView === "list"}
                    onChange={handleTileView}
                  />
                  <label htmlFor="list">List</label>
                </div>
                <div className="flex items-center gap-1 text-xs">
                  <input
                    type="radio"
                    id="grid"
                    value={`grid`}
                    checked={selectedThemeView === "grid"}
                    onChange={handleTileView}
                  />
                  <label htmlFor="grid">Grid</label>
                </div>
              </div>
              {/* tile Border */}
              <div className="flex gap-3 items-center">
                <p className=" my-2">Product tile Border</p>

                <div className="flex items-center gap-1 text-xs">
                  <input
                    type="radio"
                    id="none"
                    value="none"
                    checked={selectedThemeTileBorder === "none"}
                    onChange={handleBorderTile}
                  />
                  <label htmlFor="none">None</label>
                </div>
                <div className="flex items-center gap-1 text-xs">
                  <input
                    type="radio"
                    id="light"
                    value={`light`}
                    checked={selectedThemeTileBorder === "light"}
                    onChange={handleBorderTile}
                  />
                  <label htmlFor="light">Light</label>
                </div>
                <div className="flex items-center gap-1 text-xs">
                  <input
                    type="radio"
                    id="heavy"
                    value={`heavy`}
                    checked={selectedThemeTileBorder === "heavy"}
                    onChange={handleBorderTile}
                  />
                  <label htmlFor="heavy">Heavy</label>
                </div>
              </div>

              {/* tile style */}

              <div>
                <p className="py-2  ">Product Tile</p>
                <div className="flex flex-col md:flex-row items-center justify-around">
                  <div
                    onClick={() => setSelectedThemeTileBorderStyle("")}
                    className={` relative flex flex-col items-center justify-center w-full`}
                  >
                    <div
                      className={` ${
                        selectedThemeTileBorder === "light"
                          ? `${selectedTheme} p-0.25`
                          : selectedThemeTileBorder === "heavy"
                          ? `${selectedTheme} p-0.5`
                          : ""
                      }  
                    ${
                      selectedThemeTileBorderStyle === ""
                        ? ""
                        : "cursor-pointer opacity-40"
                    } w-full md:w-60`}
                    >
                      <div className=" flex bg-white shadow-md p-1 gap-1">
                        <div className="w-16 h-16 bg-gray-500 rounded-lg" />
                        <div className="w-full space-y-2 p-1">
                          <div className=" h-4 bg-gray-300 rounded-xl" />
                          <div className="w-12 h-2 bg-gray-300 rounded-xl" />
                        </div>
                      </div>
                    </div>
                    <p className="text-xs text-gray-400">Square</p>
                    {selectedThemeTileBorderStyle === "" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#70ab77"
                        className="absolute top-0 right-1 md:right-10 flex-shrink-0 w-6 h-6 text-violet-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>

                  <div
                    onClick={() =>
                      setSelectedThemeTileBorderStyle("rounded-lg")
                    }
                    className={` relative flex flex-col items-center justify-center w-full`}
                  >
                    <div
                      className={` ${
                        selectedThemeTileBorder === "light"
                          ? ` ${selectedTheme}  p-0.25`
                          : selectedThemeTileBorder === "heavy"
                          ? ` ${selectedTheme} p-0.5`
                          : ""
                      } 
                    ${
                      selectedThemeTileBorderStyle === "rounded-lg"
                        ? ""
                        : "cursor-pointer opacity-40"
                    } w-full md:w-60 rounded-lg`}
                    >
                      <div className=" flex bg-white rounded-lg shadow-md p-1 gap-1">
                        <div className="w-16 h-16  bg-gray-500 rounded-lg" />
                        <div className="w-full space-y-2 p-1">
                          <div className=" h-4 bg-gray-300 rounded-xl" />
                          <div className="w-12 h-2 bg-gray-300 rounded-xl" />
                        </div>
                      </div>
                    </div>
                    <p className="text-xs text-gray-400">Rounded</p>
                    {selectedThemeTileBorderStyle === "rounded-lg" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#70ab77"
                        className="absolute top-0 right-1 md:right-10 flex-shrink-0 w-6 h-6 text-violet-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <hr class="mt-4 border-b-1 border-gray-200" />
              {/* font */}

              <div className="flex gap-4 items-center md:justify-center ">
                {/* <div className="flex flex-col gap-4  "> */}
                <p className="text-gray-400 text-sm font-semibold ">
                  FONT STYLES
                </p>
                <select
                  className={`${selectedThemeFont}  focus:outline-none placeholder-gray-600 shadow-md text-sm p-2 md:p-2  cursor-pointer bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-md`}
                  required
                  value={selectedThemeFont}
                  onChange={(e) => setselectedThemeFont(e.target.value)}
                >
                  {fontData.map((item, index) => (
                    <option
                      key={index}
                      value={item.font}
                      className={` ${
                        selectedThemeFont === item.font
                          ? "opacity-80 border-gray-800 border"
                          : "cursor-pointer"
                      } ${
                        item.font
                      } p-2 px-4 md:px-6 rounded-md shadow-sm capitalize `}
                    >
                      {item.font}
                    </option>
                  ))}
                </select>

                {/* <div className="grid md:grid-cols-2">
                  {fontData.map((item, index) => (
                    <p
                      key={index}
                      value={item.font}
                      onClick={() => setselectedThemeFont(item.font)}
                      className={` ${
                        selectedThemeFont === item.font
                          ? "opacity-80 border-gray-800 border"
                          : "cursor-pointer"
                      } ${item.font} p-2 px-4 md:px-6 rounded-md shadow-sm `}
                    >
                      Select font for your Shop.
                    </p>
                  ))}
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-end mt-8 gap-4">
            <div className=" relative w-full">
              <p
                onClick={() => setShowTheme(false)}
                className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
              >
                Back
              </p>
            </div>
            <button
              onClick={() => setShowTheme(false)}
              className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
            >
              Save
            </button>
          </div>
        </>
      )}

      {/* booking slots section */}
      {showBookingSlots && !showTheme && (
        <>
          <div className="">
            <h6 class="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Add Time Slots
            </h6>

            <div className="flex gap-3 items-center mb-3">
              <p className="text-xs md:text-sm">
                Allow multiple bookings in same time period?
              </p>
              <ReactSwitch
                checked={multipleBooking}
                onChange={() => setMultipleBooking(!multipleBooking)}
                height={20}
                handleDiameter={12}
                width={40}
              />
            </div>

            {/* Weekdays Navbar */}
            <div className=" m-2 flex w-full md:justify-center items-center overflow-x-auto overflow-y-hidden mx-auto  ">
              {[
                { sm: "Sun", md: "Sunday" },
                { sm: "Mon", md: "Monday" },
                { sm: "Tue", md: "Tuesday" },
                { sm: "Wed", md: "Wednesday" },
                { sm: "Thu", md: "Thursday" },
                { sm: "Fri", md: "Friday" },
                { sm: "Sat", md: "Saturday" },
              ].map((item, index) => (
                <p
                  className={`${
                    selectedBookingDay === item.md
                      ? "bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text border border-b-0 rounded-t-lg "
                      : " border-b text-gray-600"
                  } md:px-3 px-2  border-pink-200 text-sm `}
                  onClick={() => setSelectedBookingDay(item.md)}
                >
                  <span className="hidden md:block">{item.md}</span>
                  <span className="md:hidden ">{item.sm}</span>
                </p>
              ))}
            </div>

            {/* Sunday */}
            {selectedBookingDay === "Sunday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddSundaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsSundayMenuOpen(!isSundayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isSundayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          {/* <p onClick={() => setSundaySlots(SundaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Sunday</p> */}
                          <p
                            onClick={() => setSundaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          <p
                            onClick={() => setSundaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          <p
                            onClick={() => setSundaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          <p
                            onClick={() => setSundaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          <p
                            onClick={() => setSundaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          <p
                            onClick={() => setSundaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {SundaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...SundaySlots];
                            updatedItems[index].from = e;
                            setSundaySlots(updatedItems);
                          }}
                          defaultValue={"from"}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...SundaySlots];
                            updatedItems[index].to = e;
                            setSundaySlots(updatedItems);
                          }}
                          defaultValue={"to"}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <button
                        onClick={() => {
                          const updatedItems = [...SundaySlots];
                          updatedItems.splice(index, 1);
                          setSundaySlots(updatedItems);
                          setSundaySlotsCount(SundaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Monday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddMondaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsMondayMenuOpen(!isMondayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isMondayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setMondaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          {/* <p onClick={() => setMondaySlots(MondaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Monday</p> */}
                          <p
                            onClick={() => setMondaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          <p
                            onClick={() => setMondaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          <p
                            onClick={() => setMondaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          <p
                            onClick={() => setMondaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          <p
                            onClick={() => setMondaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {MondaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...MondaySlots];
                            updatedItems[index].from = e;
                            setMondaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...MondaySlots];
                            updatedItems[index].to = e;
                            setMondaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...MondaySlots];
                          updatedItems.splice(index, 1);
                          setMondaySlots(updatedItems);
                          setMondaySlotsCount(MondaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Tuesday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddTuesdaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsTuesDayOpen(!isTuesdayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isTuesdayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setTuesdaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          <p
                            onClick={() => setTuesdaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          {/* <p onClick={() => setTuesdaySlots(TuesdaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Tuesday</p> */}
                          <p
                            onClick={() => setTuesdaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          <p
                            onClick={() => setTuesdaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          <p
                            onClick={() => setTuesdaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          <p
                            onClick={() => setTuesdaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {TuesdaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...TuesdaySlots];
                            updatedItems[index].from = e;
                            setTuesdaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...TuesdaySlots];
                            updatedItems[index].to = e;
                            setTuesdaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...TuesdaySlots];
                          updatedItems.splice(index, 1);
                          setTuesdaySlots(updatedItems);
                          setTuesdaySlotsCount(TuesdaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Wednesday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddWednesdaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() =>
                        setIsWednesdayMenuOpen(!isWednesdayMenuOpen)
                      }
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isWednesdayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setWednesdaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          <p
                            onClick={() => setWednesdaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          <p
                            onClick={() => setWednesdaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          {/* <p onClick={() => setWednesdaySlots(WednesdaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Wednesday</p> */}
                          <p
                            onClick={() => setWednesdaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          <p
                            onClick={() => setWednesdaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          <p
                            onClick={() => setWednesdaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {WednesdaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...WednesdaySlots];
                            updatedItems[index].from = e;
                            setWednesdaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...WednesdaySlots];
                            updatedItems[index].to = e;
                            setWednesdaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...WednesdaySlots];
                          updatedItems.splice(index, 1);
                          setWednesdaySlots(updatedItems);
                          setWednesdaySlotsCount(WednesdaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Thursday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddThursdaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsThursdayMenuOpen(!isThursdayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isThursdayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setThursdaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          <p
                            onClick={() => setThursdaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          <p
                            onClick={() => setThursdaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          <p
                            onClick={() => setThursdaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          {/* <p onClick={() => setThursdaySlots(ThursdaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Thursday</p> */}
                          <p
                            onClick={() => setThursdaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          <p
                            onClick={() => setThursdaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {ThursdaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...ThursdaySlots];
                            updatedItems[index].from = e;
                            setThursdaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...ThursdaySlots];
                            updatedItems[index].to = e;
                            setThursdaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...ThursdaySlots];
                          updatedItems.splice(index, 1);
                          setThursdaySlots(updatedItems);
                          setThursdaySlotsCount(ThursdaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Friday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddFridaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsFridayMenuOpen(!isFridayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isFridayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setFridaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          <p
                            onClick={() => setFridaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          <p
                            onClick={() => setFridaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          <p
                            onClick={() => setFridaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          <p
                            onClick={() => setFridaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          {/* <p onClick={() => setFridaySlots(FridaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Friday</p> */}
                          <p
                            onClick={() => setFridaySlots(SaturdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Saturday
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {FridaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...FridaySlots];
                            updatedItems[index].from = e;
                            setFridaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...FridaySlots];
                            updatedItems[index].to = e;
                            setFridaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...FridaySlots];
                          updatedItems.splice(index, 1);
                          setFridaySlots(updatedItems);
                          setFridaySlotsCount(FridaySlotsCount - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedBookingDay === "Saturday" && (
              <>
                <div className="space-y-2 flex flex-col max-w-lg mx-auto">
                  <div className="flex items-center justify-between">
                    <div
                      onClick={handleAddSaturdaySlot}
                      className="flex my-3 cursor-pointer w-20 items-center gap-2 bg-gradient-to-br from-purple-300 to-pink-500 px-2 p-1 rounded-lg shadow-sm "
                    >
                      <p className="text-white text-xs ">Add</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4"
                      >
                        <path
                          d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      onClick={() => setIsSaturdayMenuOpen(!isSaturdayMenuOpen)}
                      className="w-6 h-6 relative "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 rounded-md p-0.5 shadow-sm"
                      >
                        <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"></path>
                      </svg>

                      {isSaturdayMenuOpen && (
                        <div className="absolute top-2 right-6 text-xs w-40 text-left space-y-1 z-50 bg-white p-4 rounded-md shadow-md">
                          <p
                            onClick={() => setSaturdaySlots(SundaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Sunday
                          </p>
                          <p
                            onClick={() => setSaturdaySlots(MondaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Monday
                          </p>
                          <p
                            onClick={() => setSaturdaySlots(TuesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Tuesday
                          </p>
                          <p
                            onClick={() => setSaturdaySlots(WednesdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Wednesday
                          </p>
                          <p
                            onClick={() => setSaturdaySlots(ThursdaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Thursday
                          </p>
                          <p
                            onClick={() => setSaturdaySlots(FridaySlots)}
                            className="text-gray-500 hover:text-gray-900 py-1 "
                          >
                            Copy From Friday
                          </p>
                          {/* <p onClick={() => setSaturdaySlots(SaturdaySlots)} className="text-gray-500 hover:text-gray-900 py-1 ">Copy From Saturday</p> */}
                        </div>
                      )}
                    </div>
                  </div>
                  {SaturdaySlots?.map((item, index) => (
                    <div key={index} className="flex gap-2 items-center ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>From</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...SaturdaySlots];
                            updatedItems[index].from = e;
                            setSaturdaySlots(updatedItems);
                          }}
                          value={item.from}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <p>To</p>
                        <MobileTimePicker
                          className={` border-none  `}
                          onChange={(e) => {
                            const updatedItems = [...SaturdaySlots];
                            updatedItems[index].to = e;
                            setSaturdaySlots(updatedItems);
                          }}
                          value={item.to}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <button
                        onClick={() => {
                          const updatedItems = [...SaturdaySlots];
                          updatedItems.splice(index, 1);
                          setSaturdaySlots(updatedItems);
                          setSaturdaySlotsCount(SaturdaySlots - 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <path
                            d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                            fill="red"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col md:flex-row justify-end mt-8 gap-4">
            <div className=" relative w-full">
              <p
                onClick={() => setShowBookingSlots(false)}
                className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
              >
                Back
              </p>
            </div>
            <button
              onClick={() => setShowBookingSlots(false)}
              className="cursor-pointer w-full  shadow-md md:px-6 py-1 md:p-2  bg-gradient-to-b from-purple-200 to-pink-300 to-20% rounded-full"
            >
              Save
            </button>
          </div>
        </>
      )}

      {/* Loading Modal*/}
      <Modal
        isOpen={isLoadingModalOpen}
        className="font-lexand  max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsLoadingModalOpen(false)}
      >
        <div className=" p-6 rounded-md h-screen flex flex-col items-center justify-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-pink-400"></div>
          </div>
        </div>
      </Modal>

      {/* Create Product Modal */}
      <Modal
        isOpen={createProductModalIsOpen}
        className=" max-w-2xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setCreateProductModalIsOpen(false)}
      >
        <button
          onClick={setCreateProductModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 button shadow-md focus:outline-none cursor-pointer rounded-md hover:bg-black hover:text-white bg-white text-md font-semibold flex-shrink"
        >
          x
        </button>
        <PostMessagePopup
          onClick={setCreateProductModalIsOpenToFalse}
          closeCreateShopModal={closeOnClick}
        />
      </Modal>
    </div>
  );
}

export default EditShopModal;
